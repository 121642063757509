import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import authApi from "../api/authApi";
import SparkApi from "../api/SparkApi";
import * as constants from '../constants/CONSTANT';

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(credentials);

    const result = await authApi.login(credentials.email, credentials.password);
    if (result.success) {
      console.log(result.authToken)
      localStorage.setItem("token", result.authToken);

      let notifications =  await SparkApi.fetchSystemNotifications();
      console.log('notifications' , notifications);
      if(notifications){
        console.log(notifications);
        notifications.forEach(function(rec){
          if(rec.type === 'SYSTEM_MAINTANANCE'){
            localStorage.setItem("notifications", JSON.stringify(notifications));
            
            return;
          }
        });
      }else{
        localStorage.removeItem("notifications");
      }

      let data =  await authApi.fetchMyImage();
      //console.log('data',data)
      if(data)
        localStorage.setItem("myimage", window.URL.createObjectURL(data));
      else
        localStorage.setItem("myimage", "/img_avatar.png");

      window.location.href = '/';
      //navigate("/");
    } else {
      setShow(true);
      setErrorMessage(result.errors);
    }
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <Container>
     
      
   
      <Row className="login-form p-3 mt-5">
        <Col lg={6} className="pt-5">
          <img src="login.png" style={{ width: "100%" }} /> 
         
        </Col>
        <Col lg={6} className="login-section">
          <center></center>
          <div className="p-5 pt-4">
            <Form onSubmit={handleSubmit}>
              <div className="mt-2 text-center mb-3">
              <div className="sidebar-header text-center" >
            <img src="login-logo.png" style={{width: "auto"}}/>
          </div>
                <h3 className="my-2 mt-4">Login</h3>
              </div>
              <Alert variant="danger" show={show} className="error-alert">
                {errorMessage}
              </Alert>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter email"
              
                  value={credentials.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                 
                  value={credentials.password}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
            <div className="pt-4 text-center">
            {/* or Login with */}
            <div className="pt-4">
             <a href="#">
              <span class="fa-stack fa-2x">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#3b5998", fontSize : "4rem"}}
                ></i>
                <i class="fa-brands fa-facebook-f fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
              </span> 
              </a>
              <a href="#">
              <span class="fa-stack fa-2x">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "tomato", fontSize : "4rem"}}
                ></i>
                <i class="fa-brands fa-google fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
              </span>
              </a>
              <a href="#">
              <span class="fa-stack fa-2x">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#0d95e8", fontSize : "4rem"}}
                ></i>
                <i class="fa-brands fa-twitter fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
              </span>
              </a>
            </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="text-center">
      <h6 className="text-center mt-3"> Powered by</h6>
      <img src="/indicrmlogo.png" width="150px"/>
      </div>
      
     
    </Container>
  );
};

export default Login;
