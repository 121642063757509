import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import * as constants from '../constants/CONSTANT';
import jwt_decode from "jwt-decode";

const Sidebar = () => {


  const [sidebar, setSidebar] = useState(true);
  const [permissions, setPermissions] = useState();
  const [userInfo, setUserInfo] = useState({});
  const location = useLocation();
  useEffect(() => {
    try {
      if (localStorage.getItem('token')) {
        let user = jwt_decode(localStorage.getItem('token'));
        setUserInfo(user);

        var perm = user.permissions.map(function (obj) {
          return obj.name;
        }).join(';')
        setPermissions(perm);
      }
    } catch (error) {
      console.log(error)
    }

  }, []);
  const hideSideBar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  }

  return (
    <>
      <nav id="sidebar" className='show' style={{ fontSize: "small" }}>
        <div>
          <div className="sidebar-header text-center" style={{ padding: "2rem 2rem 2rem 1rem" }}>
            {/* <i class="fa-solid fa-utensils fa-2xl" style={{ color: "tomato" }}></i>
            <h4 className="mt-3">Caters Manager</h4>
            <h6>Simplify Catering</h6> */}
            <img src={userInfo.sidebarbgurl} style={{ width: "80%" }} />

          </div>

          <ul className="list-unstyled components">
            <li className={`${location.pathname === '/' ? 'active' : ''}`}>

              <Link to="/" /*className="dropdown-toggle"*/ style={{ borderTop: "1px solid #fff" }}> <i className="fa-solid fa-house mx-2" ></i> Home</Link>

            </li>
            <li >
              {/* <Link to="/"> <i className="fa-solid fa-chart-simple mx-2"></i> Accounts</Link> */}
            </li>
            {/* {permissions && (permissions.indexOf(constants.VIEW_ACCOUNT) >= 0 || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/accounts') ? 'active' : ''}`}>
                <Link to="/accounts"> <i className="fa-solid fa-building mx-2"></i> Accounts</Link>
              </li>
              : ''} */}

            {permissions && (permissions.indexOf(constants.VIEW_PRODUCT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li className={`${location.pathname.includes('/products') ? 'active' : ''}`}>
                <Link to="/products"> <i className="fa-solid fa-chart-simple mx-2"> </i> Products</Link>
                </li>
                : ''}
            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/contacts') ? 'active' : ''}`}>
                <Link to="/contacts"> <i className="fa-solid fa-address-book mx-2"></i> Contacts</Link>
              </li> : ''} */}

              {/* <li className={`${location.pathname.includes('/items') ? 'active' : ''}`}>
              <Link to="/items"> <i className="fa fa-list-alt mx-2"></i>Items</Link>
            </li>
              
            <li  className={`${location.pathname.includes('/orders') ? 'active' : ''}`}>
              <Link to="/orders"> <i class="fa-brands fa-opencart mx-2"></i>Orders</Link>
            </li> */}

            <li  className={`${location.pathname.includes('/categories') ? 'active' : ''}`}>
              <Link to="/categories"> <i className="fa fa-bars mx-2"></i>Categories</Link>
            </li>
{/* 
            {permissions && (permissions.indexOf(constants.VIEW_BUSINESS) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/business') ? 'active' : ''}`}>
                <Link to="/business"> <i class="fa-solid fa-coins mx-2"></i> Business</Link>
              </li> : ''} */}

            {/* {permissions && (permissions.indexOf(constants.VIEW_LEAD) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/leads') ? 'active' : ''}`} onClick={hideSideBar}>
                <Link to="/leads"> <i className="fa-solid fa-bolt mx-2"></i> Leads</Link>
              </li> : ''} */}


            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"><i className="fa-solid fa-user-md mx-2"></i> Test Master</Link>
                </li>: ''}
                {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/medicaltests"><i className="fa-solid fa-hospital mx-2"></i> Medical Test</Link>
                </li>: ''} */}
{/* 
            {permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/users') ? 'active' : ''}`}>
                <Link to="/users"> <i class="fa-solid fa-user mx-2"></i>  Users</Link>
              </li> : ''} */}

            {permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/backup') ? 'active' : ''}`}>
                <Link to="/backup"> <i class="fa-solid fa-database mx-2"></i> Backup</Link>
              </li> : ''}
           

              <li className={`${location.pathname.includes('/setting') ? 'active' : ''}`}>
              <Link to="/setting"><i class="fa fa-cog mx-2" ></i>Setting</Link>
            </li>

            <li className={`${location.pathname.includes('/support') ? 'active' : ''}`}>
              <Link to="/support"> <i class="fa-solid fa-headset mx-2"></i>Support</Link>
            </li>

            <li className={`${location.pathname.includes('/qrcode') ? 'active' : ''}`}>
              <Link to="/qrcode"> <i class="fa-solid fa-qrcode mx-2"></i>QR Code</Link>
            </li>


            <li className={`${location.pathname.includes('/myprofile') ? 'active' : ''}`}>
              <Link to="/myprofile"> <i className="fa fa-user-circle mx-2"></i>My Profile</Link>
            </li>





            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/changepassword') ? 'active' : ''}`}>
                <Link to="/changepassword"><i className="fa-solid fa-key mx-2"></i>Change Password</Link>
              </li> : ''} */}



          </ul>
          <div className="sidebar-header" style={{ padding: "2rem 2rem 2rem 1rem", borderTop: "1px solid #fff", textAlign: "center" }}>
          <a href="https://indicrm.io" target="_blank">
            <img src="/indicrm.png" style={{width: "140px", paddingTop: ".5rem"}} />
            </a>
            <div style={{color: "#fff", fontSize: "1.4rem", }}>
            DigiMenu
           </div>
          </div>




        </div>
      </nav>
    </>
  )
}

export default Sidebar
