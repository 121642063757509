import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import FilesCreate from "./FilesCreate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import { Link } from "react-router-dom";
import RelatedListBusiness from "./RelatedListBusiness";
import parse from "html-react-parser";

const ProductView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const product = location.state;
  const [product, setProduct] = useState(location.state ? location.state : {});
  const [relatedListPatientTests, setRelatedListPatientTests] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [modalShowMedicaltestitem, setModalShowMedicaltestitem] =
    useState(false);
  const [refreshBusinessList, setRefreshBusinessList] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountdata, setAccountData] = useState({});
  const [refreshTaskList, setRefreshTaskList] = useState();

  const [modalShow, setModalShow] = useState(false);
  //console.log("product=>" + JSON.stringify(product));

  useEffect(() => {
    fetchProduct();

    // setRefreshFileList(Date.now());
  }, []);

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListBusiness(false);
    } else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
    }
  };

  const submitFiles = () => {
    console.log("refreshFileList 1", refreshFileList);
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    console.log("refreshFileList ", refreshFileList);
    setModalShowTask(false);
  };

  const fetchProduct = () => {
    // Coming from Email
    console.log("call");
    if (location.hasOwnProperty("pathname")) {
      product.id = location.pathname.split("/")[2];
      console.log("pathnam===" + product.id);
    }
    async function initProduct() {
      let result = await SparkApi.fetchProduct(product.id);
      console.log("result:", result);
      if (result) {
        setProduct(result);
      } else {
        setProduct({});
      }
    }
    initProduct();
  };

  const deleteProduct = async (id) => {
    console.log("deleteProduct product view page...", id);
    const result = await SparkApi.deleteProduct(id);
    if (result.success) navigate(`/products`);
  };

  const editProduct = () => {
    navigate(`/products/${product.id}/e`, { state: product });
  };

  return (
    <div>
      {product && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteProduct={() => deleteProduct(product.id)}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="product"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/products">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>
                  Products
                </div>
              </Link>
            </Col>
            <Col></Col>
          </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Product
                  <h5>{product.productname}</h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editProduct(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
              <Row className="py-3 ibs-edit-form">
                <Col lg={6}>
                  <label>Category Name</label>
                  <span>{product.categoryname}</span>
                </Col>
                <Col lg={6}>
                  <label>Product Name</label>
                  <span>{product.productname}</span>
                </Col>

                <Col lg={6}>
                  <label>Product Code</label>
                  <span>{product.productcode}</span>
                </Col>
                <Col lg={6}>
                  <label>Making Charges</label>
                  <span>{product.makingcharge}</span>
                </Col>
                <Col lg={6}>
                  <label>Product Type</label>
                  <span>{product.type}</span>
                </Col>
                <Col lg={6}>
                  <label>Product Weight</label>
                  <span>{product.productweight}</span>
                </Col>
                <Col lg={6}>
                  <label>Product Height</label>
                  <span>{product.height}</span>
                </Col>
                <Col lg={6}>
                  <label>Product Width</label>
                  <span>{product.width}</span>
                </Col>
                {/* yamini 28-08-2023  */}
                <Col lg={6}>
                  <label>Price</label>
                  <span>{product.price}</span>
                </Col>
                <Col lg={1}>
                  <label>Status</label>
                  <span>
                    <Form.Check
                      type="checkbox"
                      name="status"
                      checked={product.isactive}
                      disabled
                    />
                  </span>
                </Col>
                <Col lg={5}>
                  <label>Product Sort Order</label>
                  <span>{product.sortorder}</span>
                </Col>

                <Col lg={1}>
                  <label>Offer</label>
                  <span>
                    <Form.Check
                      type="checkbox"
                      name="showoffer"
                      checked={product.showoffer}
                      disabled
                    />
                  </span>
                </Col>
                <Col lg={5}>
                  <label>Offer Label</label>
                  <span>{product.offerlabel}</span>
                </Col>

                {/* add "videourl" by yamini 12-01-2024 */}
                <Col lg={6}>
                  <label>Video URL</label>
                  <span className="text-truncate">{product.videourl}</span>
                </Col>

                {/* yamini 25-08-2023  */}
                <Col lg={12}>
                  <label>Description</label>
                  <span>{product.description}</span>
                </Col>
                <Col lg={12}>
                  <label>Formatted Description</label>
                  {/* <div className="formatted-desc">{parse(product.formatted_description)}</div> */}
                  <div className="formatted-desc">
                    {product.formatted_description
                      ? parse(product.formatted_description)
                      : ""}
                  </div>
                  {/* <div dangerouslySetInnerHTML={{ __html: product.formatted_description }}></div> */}
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={product}
                  table="product"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={product.id}
                  table="product"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {/* {relatedListPatientTests && <RelatedListPatientTests parent={product}/>} */}

              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={product}
                  objectType="product"
                />
              )}
              {reletedListTask ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={product}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};
export default ProductView;
