import React, { useEffect, useRef, useState } from "react";
import * as constants from "../constants/CONSTANT";
import ReactStars from "react-stars";
// yamini 31-08-2023
import SparkApi from "../api/SparkApi";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const MenuItemWide = ({
  item,
  tenantCode,
  companyNumber,
  handleSocialIcon,
  companySetting,
}) => {
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState();
  const vidRef = useRef();
  // yamini 31-08-2023
  const [offerLabelColor, setOfferLabelColor] = useState();

  const [videoStyle, setVideoStyle] = useState({
    display: "none",
    width: "75%",
    // margin: "0px 0px -20px 1px",
  });

  const [imageStyle, setImageStyle] = useState({
    display: "flex",
    width: window.innerWidth <= 768 ? "" : "45%",
    alignItems: window.innerWidth <= 768 ? "" : "center",
    justifyContent: window.innerWidth <= 768 ? "" : "center",
  });
  const [playIcon, setPlayIcon] = useState({
    display: "block",
    cursor: "pointer",
    fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
  });
  const [pauseIcon, setPauseIcon] = useState({
    display: "none",
    cursor: "pointer",
    fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
  });
  const [isShow, setIsShow] = useState(false);

  const playVideo = () => {
    vidRef.current.play();
    setVideoStyle({
      display: "block",
      width: window.innerWidth <= 768 ? "100px" : "154px",
    });
    setImageStyle({
      display: "none",
      width: window.innerWidth <= 768 ? "100px" : "154px",
    });

    setPauseIcon({
      cursor: "pointer",
      fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
    });
    setPlayIcon({
      display: "none",
      cursor: "pointer",
      fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
    });
  };

  const pauseVideo = () => {
    setVideoStyle({
      display: "none",
      width: window.innerWidth <= 768 ? "100px" : "154px",
    });
    setImageStyle({
      display: "block",
      width: window.innerWidth <= 768 ? "100px" : "154px",
    });

    setPauseIcon({
      display: "none",
      cursor: "pointer",
      fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
    });
    setPlayIcon({
      display: "block",
      cursor: "pointer",
      fontSize: window.innerWidth <= 768 ? "1rem" : "2rem",
    });
  };

  useEffect(() => {
    async function init() {
      let image =
        item.files != null
          ? constants.PRODUCT_IMAGE_BASE_URL +
            "/images/" +
            tenantCode +
            "/" +
            item.id +
            "/" +
            item.files[0].Id +
            "." +
            item.files[0].fileType
          : `/NoProductFound.svg`;
      setImageURL(image);
      setIsShow(true);
      // Start yamini 31-08-2023
      const configurationSetting =
        await SparkApi.fetchCompanySetting(`${tenantCode}_card_setting`)
        console.log('configurationSetting  : ', configurationSetting);
      const data = JSON.parse(configurationSetting?.configuration);
      console.log("data", data);
      console.log("data.offerlabelcolor", data.offerlabelcolor);
      setOfferLabelColor(data.offerlabelcolor);
      // End yamini 31-08-2023
    }
    init();
  }, [item]);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  // const [ribbonColor , setRibbonColor] = useState({
  //   backgroundColor : item.offerlabelcolor
  // })

  const createHtml = (field, value) => {
    console.log("field  :-  ", field);
    console.log("value  :-  ", value);
    if (field.value === "rating") {
      return (
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={18}
          color2={"#dc3545"}
          value={4}
        />
      );
    } else if (
      field.value !== "rating" &&
      field.isPrimary &&
      field.sortOrder === 1
    ) {
      return (
        <Link to={"/menu/" + tenantCode + "/p/" + item.id} state={item}>
          <div className="row">
            <div className="col-lg-8  col-md-8  col-sm-12 col-xs-12">
              <h5
                className="card-title float-left"
                style={{
                  color: "rgb(79, 50, 103)",
                  // overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "12rem",
                }}
              >
                {value}
              </h5>
            </div>
            {/* yamini 31-08-2023  change style*/}
            {item.showoffer ? (
              <div
                className="col-lg-4  col-md-4  col-sm-12 col-xs-12"
                align="right"
              >
                <span
                  className="ribbon-2-menu-wide"
                  style={{ background: offerLabelColor }}
                >
                  {" "}
                  {item.offerlabel}{" "}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    } else if (
      field.value !== "rating" &&
      field.isPrimary &&
      field.sortOrder === 2
    ) {
      return value;
    } else if (
      field.value !== "rating" &&
      field.label !== "formatted_description" &&
      field.isPrimary &&
      field.sortOrder === 3
    ) {
      return <p className="card-text product-descrption">{value}</p>;
    } else if (
      field.value !== "rating" &&
      field.label === "formatted_description" &&
      field.isPrimary &&
      field.sortOrder === 3
    ) {
      return (
        <div style={{ height: "3.1rem" }} className="formatted-desc">
          {value ? parse(value) : ""}
        </div>
      );
    } else {
      return (
        <>
          <label className="fw-bold" style={{ textTransform: "uppercase" }}>
            {field.label}
          </label>
          <p className="card-text">{value}</p>
        </>
      );
    }
  };

  return (
    <>
      <div
        className="container pb-2"
        style={{ borderBottom: "1px solid #80808057" }}
      >
        <div className="row">
          {/* <div className="col-12 col-md-1 ">
            <i
              className="fa-solid fa-circle-play fa-2x"
              style={playIcon}
              onClick={() => {
                playVideo();
              }}
            ></i>
            <i
              className="fa-solid fa-circle-pause fa-2x"
              style={pauseIcon}
              onClick={() => {
                pauseVideo();
              }}
            ></i>
          </div> */}
          <div className="col-4 col-md-3  ">
            <div>
              {item.videourl ? (
                <>
                  <i
                    className="fa-solid fa-circle-play fa-2x"
                    style={playIcon}
                    onClick={() => {
                      playVideo();
                    }}
                  ></i>
                  <i
                    className="fa-solid fa-circle-pause fa-2x"
                    style={pauseIcon}
                    onClick={() => {
                      pauseVideo();
                    }}
                  ></i>
                </>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div style={imageStyle}>
                {item.files != null ? (
                  <Carousel
                    className="custom-carousel"
                    controls={item.files != null && item.files.length > 1}
                    interval={null}
                    indicators={item.files != null && item.files.length > 1}
                  >
                    {item.files?.map((value, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div
                            className="menuwide-card-img-container"
                            style={{
                              backgroundImage: `url('${constants.PRODUCT_IMAGE_BASE_URL}/images/${tenantCode}/${item.id}/${value.Id}.${value.fileType}')`,
                            }}
                          ></div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div
                    className="menuwide-card-img-container mt-1"
                    style={{
                      backgroundImage: `url('${constants.PRODUCT_IMAGE_BASE_URL}/NoProductFound.svg')`,
                    }}
                  ></div>
                )}
              </div>
              <div style={{ position: "relative" }}>
                {item.videourl ? (
                  <>
                    <video
                      className="menu-item menuwide-card-img-container"
                      style={videoStyle}
                      src={item.videourl}
                      ref={vidRef}
                      muted
                      autoPlay
                      // playsinline
                      loop
                      preload="none"
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-8 col-md-8">
            <div className="card-body pb-2">
              <div>
                {/* {console.log('companySetting.primaryFields[0].label :-  ',companySetting.primaryFields)}; */}
                {companySetting?.primaryFields?.length > 0 ? (
                  // <h5
                  //     className="card-title float-left"
                  //     style={{
                  //       color: "rgb(79, 50, 103)",
                  //       overflow: "hidden",
                  //       textOverflow: "ellipsis",
                  //       whiteSpace: "nowrap",
                  //       width: "14rem",
                  //     }}
                  //   >
                  //     {/* {console.log('companySetting.primaryFields[0].label :-  ',companySetting.primaryFields[0].label)} */}
                  //     {item[companySetting.primaryFields[0].label]}
                  //   </h5>
                  <div>
                    {createHtml(
                      companySetting.primaryFields[0],
                      item[companySetting.primaryFields[0].value]
                    )}
                  </div>
                ) : (
                  ""
                )}
                {companySetting?.primaryFields?.length > 1 ? (
                  <div className="pt-2" style={{ lineHeight: "20px" }}>
                    {createHtml(
                      companySetting.primaryFields[1],
                      item[companySetting.primaryFields[1].value]
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pt-2">
                {companySetting?.primaryFields?.length > 2 ? (
                  <span>
                    {createHtml(
                      companySetting.primaryFields[2],
                      item[companySetting.primaryFields[2].value]
                    )}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {companySetting?.secondaryFields?.length ? (
                <div className="row pb-2">
                  {companySetting.secondaryFields.map((element) => {
                    return (
                      <div
                        key={element.id}
                        className="col-lg-6 col-sm-4 col-md-6 pt-2"
                      >
                        {/* <label className='fw-bold'>{element.value}</label>
              <p className="card-text">{item[element.value]}</p> */}
                        {createHtml(element, item[element.value])}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              <div className="d-flex align-items justify-content-start pt-4">
                <div style={{ cursor: "pointer", textAlign: "center" }}>
                  {/* href={'https://wa.me/8619951193?text=Hello%0a'+ constants.PRODUCT_IMAGE_BASE_URL + '/images/' + tenantCode + '/' + item.files } */}

                  {companyNumber ? (
                    <>
                      {/* <a
                                                className=""
                                                target="_blank"
                                                href={
                                                    "https://wa.me/" +
                                                    companyNumber.replaceAll("-", "") +
                                                    "?text=" +
                                                    constants.enquiryMessage
                                                        .replaceAll("{productname}", item.productname)
                                                        .replaceAll("{productimage}", imageURL)
                                                }
                                            > */}
                      {/* <i 
                                                
                                                className="fa-brands fa-whatsapp"
                                                style={{
                                                    fontSize: "1.5rem",
                                                    color: "#54ee72",
                                                    cursor: "pointer",
                                                }}></i> */}

                      <a
                        target="blank"
                        href={
                          "https://wa.me/" +
                          companyNumber.replaceAll("-", "") +
                          "?text=" +
                          constants.enquiryMessage
                            .replaceAll("{productname}", item.productname)
                            .replaceAll("{productimage}", imageURL)
                        }
                      >
                        <img
                          src="/whatsapp-whats-app-svgrepo-com.svg"
                          width="24px"
                        />
                      </a>

                      <div> {t("enquiry")}</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="ps-5"
                  tabIndex={0}
                  onClick={() => handleSocialIcon(item)}
                  onBlur={() => handleSocialIcon(item)}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  {/* <i

                                        className="fa-solid fa-arrow-up-right-from-square"
                                        style={{
                                            fontSize: "1.5rem",
                                            color: "#47525dcc",
                                            cursor: "pointer",
                                        }}
                                    ></i> */}
                  <img src="/share-svgrepo-com.svg" width="24px" />
                  <div>{t("share")}</div>
                  {item.showSocial && (
                    <div
                      style={{
                        position: "absolute",
                        // right: "0rem",
                        width: "10rem",
                        zIndex: "100",
                        borderRadius: "10px",
                        padding: ".5rem",
                        backgroundColor: "#f0f1f1",
                      }}
                    >
                      <FacebookShareButton
                        url={
                          "https://digimenu.indicrm.io/menu/" +
                          tenantCode +
                          "/p/" +
                          item.id
                        }
                        quote={tenantCode}
                        hashtag={tenantCode}
                        className="mx-1"
                      >
                        <FacebookIcon size={24} round />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        // url={"https://digimenu.indicrm.io/menu/" + tenantCode}

                        url={
                          "https://digimenu.indicrm.io/menu/" +
                          tenantCode +
                          "/p/" +
                          item.id
                        }
                        quote={tenantCode}
                        hashtag={tenantCode}
                        className="mx-1"
                      >
                        <WhatsappIcon size={24} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton
                        url={
                          "https://digimenu.indicrm.io/menu/" +
                          tenantCode +
                          "/p/" +
                          item.id
                        }
                        quote={tenantCode}
                        hashtag={tenantCode}
                        className="mx-1"
                      >
                        <LinkedinIcon size={24} round />
                      </LinkedinShareButton>
                      <TelegramShareButton
                        url={
                          "https://digimenu.indicrm.io/menu/" +
                          tenantCode +
                          "/p/" +
                          item.id
                        }
                        quote={tenantCode}
                        hashtag={tenantCode}
                        className="mx-1"
                      >
                        <TelegramIcon size={24} round />
                      </TelegramShareButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItemWide;
