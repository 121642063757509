import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactStars from "react-stars";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import * as constants from "../constants/CONSTANT";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import SparkApi from "../api/SparkApi";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import ThumbnailImageContainer from "./ThumbnailImageContainer";

const ProductDetail = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [numRowsToShow, setNumRowsToShow] = useState(6);
  const [productData, setProductData] = useState({});
  console.log("productData==>", productData);
  let tCode = location.pathname.split("/")[2];
  let productId = location.pathname?.split("/")[4];

  const [imageURL, setImageURL] = useState("");
  const [companyNumber, setCompanyNumber] = useState();
  const [isSocialIcon, setSocialIcon] = useState(false);
  const [isShowRows, setShowRows] = useState(false);
  const [isShowMore, setShowMore] = useState("See More");
  const [isIconClass, setIconClass] = useState("fa-solid fa-angle-down");
  // yamini 31-08-2023
  const [offerLabelColor , setOfferLabelColor] = useState();

  useEffect(() => {
    setCompanyNumber(props.companyInfoData.phone);
    window.scrollTo(0, 0);
  }, [props]);

  useEffect(() => {
    async function init() {
      let prodetail = await SparkApi.fetchProductDetail(productId, tCode);
      if (prodetail != null) {
        let prdArray = [];
        // console.log('prodetail' , prodetail)
        // setRibbonColor({backgroundColor : prodetail.offerlabelcolor})
        if (prodetail?.files) {
          let files = `[${prodetail.files.replaceAll("@#", ",")}]`;
          // console.log('files :-  ',files);
          prodetail.files = JSON.parse(files);
        }
        prdArray.push(prodetail);
        console.log(" prdArray --> ", prdArray);
        let prd = prdArray && prdArray.length ? prdArray[0] : {};
        setProductData(prd);
      } else {
        setProductData({});
      }
      let tenantCode = location.pathname.split("/")[2];

      // Start yamini 31-08-2023
      const configurationSetting =
        await SparkApi.fetchCompanySetting(`${tenantCode}_card_setting`)
      const data = JSON.parse(configurationSetting.configuration)
      console.log('data' , data)
      console.log('data.offerlabelcolor' , data.offerlabelcolor)
      setOfferLabelColor(data.offerlabelcolor)
    // End yamini 31-08-2023
    }
    init();
    // getCompanySettings();
  }, []);

  useEffect(() => {
    let image =
      productData.files != null
        ? constants.PRODUCT_IMAGE_BASE_URL +
          "/images/" +
          tCode +
          "/" +
          productData.id +
          "/" +
          productData.files[0].Id +
          "." +
          productData.files[0].fileType
        : `/NoProductFound.svg`;
    console.log("image", image);
    setImageURL(image);
  }, [
    constants.PRODUCT_IMAGE_BASE_URL,
    tCode,
    productData.id,
    productData.files,
  ]);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const handleSocialIcon = (productData) => {
    setSocialIcon(!isSocialIcon);
  };
  const handleSeeMore = () => {
    setShowRows(!isShowRows);
    if (isShowMore === "See Less") {
      setShowMore("See More");
      setIconClass("fa-solid fa-angle-down");
    } else {
      setShowMore("See Less");
      setIconClass("fa-solid fa-angle-up");
    }
  };
      
  return (
    <>
      
      <div className="  container">
        <div className="p-3 my-3" style={{ background: "white" }}>
          <Link
            className="detail-back"
            to={"/menu/" + props.companyInfoData.tenantcode}
          >
            Home
          </Link>
          <i className="fa-solid fa-chevron-right px-1"></i>
          <div
            style={{
              color: "rgb(35, 70, 140)",
              display: "inline",
              fontSize: "medium",
            }}
          >
            {productData.productname}
          </div>
          
        </div>
      </div>
      <Container className="mb-1 mainbody">
        <div className="container" style={{ background: "white" }}>
          <div className="row my-3">
            <div className="">
              <Row style={{ backgroundColor: "white" }} className="px-2">
                {/* <Col lg={6} md={12} sm={12} xs={12} className="py-4">
                                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner" >
                                            {productData?.files ? productData?.files?.map((value, index) => (
                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                                    
                                                    <div className="card-img-slider" style={{backgroundImage: `url(${constants.PRODUCT_IMAGE_BASE_URL}/images/${tCode}/${productData.id}/${value.Id}.${value.fileType})`}}>
                                                        
                                                    </div>
                                                </div>
                                            )) : <div className={`carousel-item active`}>
                                            <img src={`${constants.PRODUCT_IMAGE_BASE_URL}/NoProductFound.svg`} className="d-block img-fluid w-100" alt="..." />
                                        </div>}
                                            
                                        </div>
                                        {productData?.files && productData?.files?.length > 1 && (
                                            <>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev" >
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>

                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next" >
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </>
                                        )} 
                                        <ol className="carousel-indicators">
                                            {productData?.files?.map((value, index) => (
                                                <li
                                                    key={index}
                                                    data-bs-target="#carouselExampleCaptions"
                                                    data-bs-slide-to={index}
                                                    className={index === 0 ? 'active' : ''}
                                                ></li>
                                            ))}
                                        </ol>
                                    </div>
                                    <div className="thumbnail-container d-flex justify-content-center mt-3">
                                        {productData?.files?.map((value, index) => (
                                            <img
                                                key={index}
                                                src={`${constants.PRODUCT_IMAGE_BASE_URL}/images/${tCode}/${productData.id}/${value.Id}.${value.fileType}`}
                                                alt={`Thumbnail ${index}`}
                                                className={`img-thumbnail mx-2`}
                                                onClick={() => { document.getElementById('carouselExampleCaptions').carousel(index);}}
                                            />
                                        ))}
                                    </div>

                                </Col> */}
                <Col lg={6} md={12} sm={12} xs={12} className="py-4">
                  
                  <div
                    id="carouselExampleCaptions"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    
                    <div className="carousel-inner">
                      {productData?.files ? (
                        productData?.files?.map((value, index) => (
                          <div
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                            key={index}
                          >
                            <div
                              className="card-img-slider"
                              style={{
                                backgroundImage: `url(${constants.PRODUCT_IMAGE_BASE_URL}/images/${tCode}/${productData.id}/${value.Id}.${value.fileType})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '500px',
                                backgroundPosition: 'top',
                              }}
                            ></div>
                          </div>
                        ))
                      ) : (
                        <div className="carousel-item active">
                          <img
                            src={`${constants.PRODUCT_IMAGE_BASE_URL}/NoProductFound.svg`}
                            className="d-block img-fluid w-100"
                            alt="..."
                          />
                        </div>
                      )}
                    </div>
                    {productData?.files && productData?.files?.length > 1 && (
                      <>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </>
                    )}

                    {/* yamini 21-08-2023 */}
                    <ol className="carousel-indicators">
                      {productData?.files?.map((value, index) => (
                        <li
                          key={index}
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to={index}
                          className={index === 0 ? "active" : ""}
                        ></li>
                      ))}
                    </ol>
                  </div>
                  
                  <ThumbnailImageContainer productData={productData} constants={constants} tCode={tCode}/>
                </Col>

                <Col lg={6} md={12} sm={12} xs={12} className="py-4">
                  <div style={{ textAlign: "justify" }}>
                    <Row className="">
                      <Col lg={8} md={12} sm={12} xs={12}>
                        <h4 style={{ color: "rgb(79, 50, 103)" }}>
                          {productData.productname}
                        </h4>
                        
                      </Col>
                      <Col lg={4} md={12} sm={12} xs={12}>
                        <div className="">
                          <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={18}
                            color2={"#dc3545"}
                            value={4}
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="desc">
                          <p style={{ lineHeight: "1.5", fontSize: "1rem" }}>
                            {productData.description}
                          </p>
                        </div>
                      </Col>
                      <Col lg={9} md={9} sm={8} xs={8}>
                        <div className="d-flex   mb-3 me-4">
                          {companyNumber ? (
                            <>
                              <div
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                <a
                                  target="blank"
                                  href={
                                    "https://wa.me/" +
                                    companyNumber.replaceAll("-", "") +
                                    "?text=" +
                                    constants.enquiryMessage
                                      .replaceAll(
                                        "{productname}",
                                        productData.productname
                                      )
                                      .replaceAll("{productimage}", imageURL)
                                  }
                                >
                                  <img
                                    src="/whatsapp-whats-app-svgrepo-com.svg"
                                    width="24px"
                                  />
                                </a>
                                <div> {t("enquiry")}</div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {/* Share icon */}
                          <div
                            className="mx-3"
                            onClick={() => handleSocialIcon(productData)}
                            style={{ cursor: "pointer", textAlign: "center" }}
                          >
                            <img src="/share-svgrepo-com.svg" width="24px" />
                            <div>{t("share")}</div>
                            {isSocialIcon && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: "100",
                                  borderRadius: "10px",
                                  padding: ".5rem",
                                  backgroundColor: "#f0f1f1",
                                }}
                              >
                                <FacebookShareButton
                                  url={
                                    "https://digimenu.indicrm.io/menu/" +
                                    tCode +
                                    "/p/" +
                                    productData.id
                                  }
                                  quote={tCode}
                                  hashtag={tCode}
                                  className="mx-1"
                                >
                                  <FacebookIcon size={24} round />
                                </FacebookShareButton>
                                <WhatsappShareButton
                                  // url={"https://digimenu.indicrm.io/menu/" + tCode}

                                  url={
                                    "https://digimenu.indicrm.io/menu/" +
                                    tCode +
                                    "/p/" +
                                    productData.id
                                  }
                                  quote={tCode}
                                  hashtag={tCode}
                                  className="mx-1"
                                >
                                  <WhatsappIcon size={24} round />
                                </WhatsappShareButton>
                                <LinkedinShareButton
                                  url={
                                    "https://digimenu.indicrm.io/menu/" +
                                    tCode +
                                    "/p/" +
                                    productData.id
                                  }
                                  quote={tCode}
                                  hashtag={tCode}
                                  className="mx-1"
                                >
                                  <LinkedinIcon size={24} round />
                                </LinkedinShareButton>
                                <TelegramShareButton
                                  url={
                                    "https://digimenu.indicrm.io/menu/" +
                                    tCode +
                                    "/p/" +
                                    productData.id
                                  }
                                  quote={tCode}
                                  hashtag={tCode}
                                  className="mx-1"
                                >
                                  <TelegramIcon size={24} round />
                                </TelegramShareButton>
                              </div>
                            )}
                            
                          </div>
                          
                        </div>
                      </Col>
                      <Col lg={3} md={3} sm={4} xs={4}>
                        {/* yamini 31-08-2023 */}
                      {productData.showoffer ? <div className="ribbon-2-detail" style={{background:offerLabelColor}}>{productData.offerlabel} </div> : ""}
                      </Col>
                    </Row>

                    <div className="speci py-4">
                      <h6
                        className="text-uppercase fw-bold"
                        style={{ color: "rgb(79, 50, 103)" }}
                      >
                        Product Details
                      </h6>
                    </div>

                    <div className="productDetil">
                      {numRowsToShow && (
                        <>
                          <Row className="">
                            <Col className="">
                              <div className="productCategory">
                                <h6 className="text-uppercase">
                                  {t("category")}
                                </h6>

                                <div class="div-content mb-2">
                                  <span className="">
                                    {productData.categoryname}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col className="">
                              <div>
                                <h6 className="text-uppercase">
                                  {t("makingcharge")}
                                </h6>

                                <div class="div-content mb-2">
                                  <span className="">
                                    {productData.makingcharge}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="">
                              <h6 className="text-uppercase">{t("height")}</h6>
                              <div class="div-content mb-2">
                                <span className="">{productData.height}</span>
                              </div>
                            </Col>
                            <Col className="">
                              <h6 className="text-uppercase">{t("width")}</h6>
                              <div class="div-content mb-2">
                                <span className="">{productData.width}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="">
                              <h6 className="text-uppercase">
                                {t("productcode")}
                              </h6>
                              <div class="div-content mb-2">
                                <span className="">
                                  {productData.productcode}
                                </span>
                              </div>
                            </Col>
                            <Col className="">
                              <h6 className="text-uppercase">{t("price")}</h6>
                              <div class="div-content mb-2">
                                <span className="">{productData.price}</span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      {isShowRows && (
                        <Row className="">
                          <Col className="">
                            <div>
                              <h6 className="text-uppercase">{t("gst")}</h6>

                              <div class="div-content mb-2">
                                <span className="">{productData.gst}</span>
                              </div>
                            </div>
                          </Col>
                          <Col className="">
                            <div>
                              <h6 className="text-uppercase">
                                {t("productweight")}
                              </h6>

                              <div class="div-content mb-2">
                                <span className="">
                                  {productData.productweight}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <div
                        style={{ cursor: "pointer" }}
                        className="seeMore fw-bold mt-3"
                        onClick={handleSeeMore}
                      >
                        {isShowMore} <i class={isIconClass}></i>
                      </div>
                    </div>

                    <div className="desDetil py-4">
                      <Row className="">
                        <Col className="">
                          <div>
                            <h6 className="text-uppercase">
                              {t("additionaldescription")}
                            </h6>
                            <div className="formatted-desc">
                              {productData.formatted_description
                                ? parse(productData.formatted_description)
                                : ""}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProductDetail;
