import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import Select from "react-select";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

const ProductEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [optionCategory, setOptionCategory] = useState({});
  const [option, setoption] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  // yamini 28-08-2023 Start

  const [product, setProduct] = useState(location.state ? location.state : {});

  // yamini 28-08-2023 End
  useEffect(() => {
    async function init() {
      const categoryData = await SparkApi.fetchCategories();
      console.log("categoryData" + JSON.stringify(categoryData));
      let cat = [];
      categoryData.forEach((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.name;
        cat.push(obj);
      });
      setOptionCategory(cat);
    }

    init();
  }, []);

  useEffect(() => {
    if (product.id) {
      let temp = {};
      temp.value = product.category;
      temp.label = product.categoryname;
      setoption(temp);
    }
  }, [optionCategory]);

  const handleCategory = (e) => {
    //console.log('e', e)
    setoption(e);
    setProduct({ ...product, category: e.value, categoryname: e.label });
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    if (type === "checkbox") {
      setProduct({ ...product, [name]: checked });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleFormattedDescription = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      formatted_description: value,
    }));
  };

  const checkRequredFields = () => {
    if (
      product.category &&
      product.category.trim() !== "" &&
      product.productname &&
      product.productname.trim() !== "" &&
      product.productcode &&
      product.productcode.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("product===>>", product);
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    if (product.id) {
      result = await SparkApi.updateProduct(product);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/products/${product.id}`, { state: product });
      }
    } else {
      result = await SparkApi.createProduct(product);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/products/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (product.id) {
      navigate("/products/" + product.id, { state: product });
    } else {
      navigate(`/products`);
    }
  };

  return (
    <Container className="view-form">
      <Row>
        <Col lg={8} className="pb-1 pt-2">
          <Link className="nav-link" to="/products">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            <div style={{ color: "#23468c", display: "inline" }}>Products</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={9}>
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={8}>
                {product.id ? (
                  <h5>Edit Product {product.productname}</h5>
                ) : (
                  <h5>Create Product</h5>
                )}
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  {product.id ? "Update" : "Save"}
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form pb-3 ">
              <Row className="mb-3">
                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Category Name
                    </Form.Label>
                    <Select
                      required
                      placeholder="Category Name... "
                      value={option}
                      onChange={handleCategory}
                      options={optionCategory}
                      //value={salutationValue}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide Category Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicTitle">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicTitle"
                    >
                      Product Name
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      required
                      name="productname"
                      placeholder="Enter Product Name"
                      value={product.productname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Product Code
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      required
                      name="productcode"
                      placeholder="Enter Product Code"
                      value={product.productcode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Making Charges
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="makingcharge"
                      placeholder="Enter Making Charges"
                      value={product.makingcharge}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Product Type
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="type"
                      placeholder="Enter Product Type"
                      value={product.type}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Product Weight
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="productweight"
                      placeholder="Enter Product Weight"
                      value={product.productweight}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicHeight">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicHeight"
                    >
                      Product Height
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="height"
                      placeholder="Enter Product Height"
                      value={product.height}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Product Width
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="width"
                      placeholder="Enter Product Width"
                      value={product.width}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {/* yamini 28-08-2023  Start*/}
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicprice">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicprice"
                    >
                      Price
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="price"
                      placeholder="Enter Product Price"
                      value={product.price}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={1}>
                  <Form.Group className="mx-3" controlId="formBasicStatus">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStatus"
                    >
                      Status
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      required
                      name="isactive"
                      checked={product.isactive}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={5}>
                  <Form.Group className="mx-3" controlId="formBasicPin">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Product Sort Order
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="sortorder"
                      placeholder="Enter Product Sort Order"
                      value={product.sortorder}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={1}>
                  <Form.Group className="mx-3" controlId="formBasicOffer">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicOffer"
                    >
                      Offer
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="showoffer"
                      checked={product.showoffer}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={5}>
                  <Form.Group className="mx-3" controlId="formBasicofferlabel">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicofferlabel"
                    >
                      Offer label
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="offerlabel"
                      placeholder="Enter Product Offer"
                      value={product.offerlabel}
                      onChange={handleChange}
                      required
                      disabled={!product.showoffer}
                    />
                  </Form.Group>
                </Col>

                {/* add "videourl" by yamini 12-01-2024 */}
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPin">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Product Video URL
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="videourl"
                      placeholder="Enter Product videourl"
                      value={product.videourl}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                
                {/* yamini 28-08-2023  End*/}
                <Col lg={12}>
                  <Form.Group className="mx-3" controlId="formBasicStreet">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      as="textarea"
                      name="description"
                      placeholder="Enter Description"
                      value={product.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mx-3" controlId="formBasicDescription">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicDescription"
                    >
                      Formatted Description
                    </Form.Label>
                    <SunEditor
                      name="formatted_description"
                      defaultValue={product.formatted_description}
                      setOptions={{
                        showPathLabel: false,
                        minHeight: "50vh",
                        maxHeight: "50vh",
                        plugins: [
                          align,
                          font,
                          fontColor,
                          fontSize,
                          formatBlock,
                          hiliteColor,
                          horizontalRule,
                          lineHeight,
                          list,
                          paragraphStyle,
                          table,
                          template,
                          textStyle,
                          image,
                          link,
                        ],
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["removeFormat"],
                          ["align", "horizontalRule", "list"],
                          ["table", "link"],
                        ],
                        formats: [
                          "p",
                          "div",
                          "h1",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                      }}
                      onChange={handleFormattedDescription}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/*<Row className="justify-content-md-center">
                          <Col lg={8}>
                              <Button  className="mx-3" variant="primary" type="submit">
                                  Submit
                              </Button>
                          </Col>
              </Row>*/}
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default ProductEdit;
