import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SparkApi from "../api/SparkApi";

const Home = () => {

  const [newLead, setNewLad] = useState("");
  const [allContacts, setAllCOntacts] = useState("");
  const [totalBusiness, setTotalBusiness] = useState("");
  const [showEventModel, setShowEventModel] = useState(false);
  const calendarRef = React.createRef();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [workingLeads, setWorkingLeads] = useState(0);

  const [totalActiveProducts, setTotalActiveProducts] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    //const st = "Open - Not Contactecd"
    async function init() {
      
      let countActiveProducts = await  SparkApi.fetchCountOfActiveProducts();
      if(countActiveProducts?.length > 0){
        setTotalActiveProducts(countActiveProducts[0]['totalproducts']);
      }
    }

    init();
  }, []);

  return (
    <Container>

      <Row >
        <Col lg={3}>
          <Link to="/products" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#f7981c' }}></i>
                <i class="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">PRODUCTS</h6>
                <h1 className='mb-0 d-inline '>{totalActiveProducts}</h1><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
       
        
      </Row>
      
    </Container>

  )
}

export default Home
