import React, { useEffect, useState } from "react";
import { Badge, Button, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import jwt_decode from "jwt-decode";

const Header = (props) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
    if(localStorage.getItem("notifications") && JSON.parse(localStorage.getItem("notifications")).length > 0) {
      navigate("/maintanance");
    }

    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
    console.log(userInfo.username);
    } catch (error) {
      console.log(error)
    }
    
    
  }, []);

  const [sidebar, setSidebar] = useState(false);
  const [imgBody, setImgBody] = useState();
  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {
  //  if(sidebar === false){
      //setSidebar(true);
      ////console.log(sidebar);
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
    //document.querySelector("#minisidebar").classList.toggle("active");
    //}

  //  else{
      //setSidebar(false);
      ////console.log(sidebar);
      // document.querySelector("#sidebar").classList.toggle("hide");
      // document.querySelector("#sidebar").classList.toggle("show");
      //document.querySelector("#minisidebar").classList.toggle("active");
   // }
    
  };



  
  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}
         
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" className="p-0" style={{fontSize: ".9rem"}}>
              <img src={localStorage.getItem("myimage")} className="rounded-circle" style={{height: "30px"}}/> 
            <Badge style={{fontSize: ".9rem"}} bg="light" text="dark">{userInfo.username} </Badge> 
            <Badge bg="success" style={{fontSize: ".9rem"}}>{userInfo.userrole} </Badge> </Nav.Link>
            
            <Nav.Link href="#" className="d-flex p-0" style={{alignItems : "center"}}><span className="mx-2" style={{fontSize: ".9rem"}}>Company</span> <Badge style={{fontSize: ".9rem"}} bg="secondary">{userInfo.companyname} </Badge> </Nav.Link>
            
            
          </Nav>

          <Nav className="ml-auto">
            <Nav.Link href="/about">
              <i className="fa-solid fa-bell"></i>
            </Nav.Link>
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
