import React, { useState, useEffect } from "react";

import { Container } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import MenuItemNarrow from "./MenuItemNarrow";
import MenuItemWide from "./MenuItemWide";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import { useTranslation } from "react-i18next";

const ProductGridView = (props) => {
  const location = useLocation();
  const [inputTerm, setInputTerm] = useState("");
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(0);
  const [currentPage, setCurrentPage] = useState();
  const [pages, setPages] = useState([]);
  const [maxPages, setMaxPages] = useState(18);
  const [term, setTerm] = useState("");
  const [tenantCode, setTenantCode] = useState([]);
  const [categoryData, setCategoryData] = useState();
  const [companySetting, setCompaySetting] = useState();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  useEffect(() => {
    async function init() {
      PubSub.subscribe("category", handleCategory);
      let tCode = location.pathname.split("/")[2];
      setTenantCode(tCode);
      await getCompanySettings(tCode);
      let products = await SparkApi.fetchPublicProducts(tCode);
      if (products && products?.length > 0) {
        let prdArray = [];
        products.forEach((element) => {
          if (element?.files) {
            let files = `[${element.files.replaceAll("@#", ",")}]`;
            // console.log('files :-  ',files);
            element.files = JSON.parse(files);
          }
          prdArray.push(element);
        });
        console.log(" prdArray --> ", prdArray);
        setItems(prdArray);
      } else {
        setItems([]);
      }
      setCurrentPage(1);
    }
    init();
    // getCompanySettings();

    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    doFilter(term, items);
  }, [items, location.pathname, currentPage, categoryData]);

  const getCompanySettings = async (tCode) => {
    console.log("tCode :-  ", tCode);
    SparkApi.fetchCompanySetting(`${tCode}_card_setting`)
      .then((result) => {
        console.log("result  :-  ", result);
        console.log("result  :-  ", JSON.parse(result.configuration));
        let comapyData = {
          mode: JSON.parse(result.configuration).mode,
          primaryFields: [],
          secondaryFields: [],
        };
        JSON.parse(result.configuration).fields.forEach((element, index) => {
          if (element.isPrimary) {
            comapyData.primaryFields = [...comapyData.primaryFields, element];
          } else {
            comapyData.secondaryFields = [
              ...comapyData.secondaryFields,
              element,
            ];
          }
        });
        console.log("comapyData  :-  ", comapyData);
        setCompaySetting(comapyData);
      })
      .catch((error) => {
        console.log("error  :-  ", error);
      });
  };

  const handleCategory = (msg, data) => {
    setCategoryData(data);
    setCurrentPage(1);
  };

  const onSearchFilter = (e) => {
    setCurrentPage(1);
    setInputTerm(e.target.value);
    doFilter(e.target.value, items);
    setTerm(e.target.value);
  };

  const doFilter = (term, products) => {
    products = filterByCategory(products);
    let newArray = products.filter(function (item) {
      if (term && term.trim().length > 0)
        return item.productname.toLowerCase().includes(term.toLowerCase());
      else return true;
    });
    devideList(newArray);
  };

  const devideList = (newArray) => {
    let productsWithChunk = [];
    for (let i = 0; i < newArray.length; i += 18) {
      const chunk = newArray.slice(i, i + 18);
      productsWithChunk.push(chunk);
    }
    createPages(productsWithChunk);
  };

  const filterByCategory = (products) => {
    let newArray = products;
    if (categoryData) {
      newArray = products.filter((element) => {
        return categoryData.categoryId === element.category;
      });
    }
    return newArray;
  };

  const createPages = (productArray) => {
    setMaxPages(productArray.length);
    let pageArray = [];
    let leftSide = currentPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = currentPage + 2;
    if (rightSide > productArray.length) rightSide = productArray.length;
    for (let number = leftSide; number <= rightSide; number++) {
      pageArray.push({ number: number });
    }
    setPages([...pageArray]);
    setFilteredItems(productArray[currentPage - 1]);
  };

  const nextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const handlePageButtons = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSocialIcon = (item) => {
    setTimeout(() => {
      let products = [];
      filteredItems.forEach((element) => {
        if (element.id === item.id) {
          element["showSocial"] =
            element["showSocial"] === undefined ? true : !element["showSocial"];
        } else {
          element["showSocial"] = false;
        }
        products.push(element);
      });
      setFilteredItems(products);
    }, 200);
    console.log(item);
  };

  return (
    <Container className="mb-1 mainbody">
      <div className="container" style={{ background: "white" }}>
        <div className="row no-gutters search-bar mb-1 mt-0">
          <div className="col-lg-12 col-sm-12 col-md-12 pt-4">
            <div className="row no-gutters search-bar mb-1 mt-0  m-1">
              <div
                className="col-lg-4 col-sm-12 col-md-12 p-0 d-flex align-items-center"
                style={{ border: "1px solid #e6e6e6", borderRadius: "5px" }}
              >
                <input
                  onChange={onSearchFilter}
                  value={inputTerm}
                  className="form-control searchinput productgridsearchbox"
                  style={{ border: "none" }}
                  type="search"
                  placeholder={t("search")}
                  id="example-search-input4"
                />
                <button
                  className="btn search-btn btn-outline-secondary border-0 rounded-0 rounded-right"
                  type="button"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>


          {/* yamini yamini 21-08-2023 Add it */}
          {filteredItems ? (
            companySetting?.mode === "narrowcard" ? (
              filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-sm-12 col-md-6 pt-4 pb-4"
                >
                  <MenuItemNarrow
                    item={item}
                    companySetting={companySetting}
                    tenantCode={tenantCode}
                    companyNumber={props.companyInfoData.phone}
                    handleSocialIcon={handleSocialIcon}
                  />
                </div>
              ))
            ) : companySetting?.mode === "widecard" ? (
              filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-12 col-sm-12 col-md-12 pt-2 pb-2"
                >
                  <MenuItemWide
                    item={item}
                    companySetting={companySetting}
                    tenantCode={tenantCode}
                    companyNumber={props.companyInfoData.phone}
                    handleSocialIcon={handleSocialIcon}
                  />
                </div>
              ))
            ) : (
              filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-sm-12 col-md-6 pt-4 pb-4"
                >
                  <MenuItem
                    item={item}
                    companySetting={companySetting}
                    tenantCode={tenantCode}
                    companyNumber={props.companyInfoData.phone}
                    handleSocialIcon={handleSocialIcon}
                  />
                </div>
              ))
            )
          ) : (
            <p className="text-center pt-4 mt-4">
              <h1>No Results Found</h1>
            </p>
          )}


          {pages && pages.length > 1 ? (
            <div className="col-lg-12 col-sm-12 col-md-12 pt-4 pb-4">
              <div className="flex-container">
                <div className="paginate-ctn">
                  <div className="round-effect" onClick={prevPage}>
                    <i class="fa fa-angle-left fa-1x"></i>
                  </div>
                  {pages
                    ? pages.map((element) => {
                        return (
                          <div
                            key={element.number}
                            pagenumber={element.number}
                            style={{ fontWeight: "bold" }}
                            className={
                              element.number === currentPage
                                ? "round-effect pageactive"
                                : "round-effect"
                            }
                            onClick={() => {
                              handlePageButtons(element.number);
                            }}
                          >
                            {element.number}
                          </div>
                        );
                      })
                    : ""}
                  <div className="round-effect" onClick={nextPage}>
                    <i class="fa fa-angle-right fa-1x"></i>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {showTopBtn ? (
        // <button className='scrolltopbutton' title="Go to top" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
        // <i class="fa-solid fa-angle-up" style={{verticalAlign: 'sub'}} aria-hidden="true"></i>
        // </button>
        <span
          class="fa-stack fa-2x scrolltopbutton"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <i
            class="fa-solid fa-circle fa-stack-1x"
            style={{ color: "#f19e3e", fontSize: "3rem" }}
          ></i>
          <i
            class="fa fa-chevron-up fa-stack-1x"
            style={{ color: "#fff", fontSize: "1.2rem" }}
          ></i>
        </span>
      ) : (
        ""
      )}
    </Container>
  );
};

export default ProductGridView;
