import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

export default function NavBar({ companyName }) {
  const [navMenus, setNavMenus] = useState([]);
  const location = useLocation();
  const [tenantCode, setTenantCode] = useState("");
  const [companyTitle, setCompanyName] = useState("");
  const [allCategoryUrl, setAllCategoryUrl] = useState("");

  useEffect(() => {
    if (companyName) {
      let htmlString = "";
      companyName.split(" ").forEach((element) => {
        let stringval = element;
        htmlString += `<span>${element.at(0)}</span>${element.substr(
          1,
          stringval.length
        )} `;
      });
      setCompanyName(htmlString);
    }
  }, [companyName]);

  useEffect(() => {
    let tCode = location.pathname.split("/")[2];
    let category_id = location.pathname.split("/")[4];
    setTenantCode(tCode);
    setAllCategoryUrl(`/menu/${tCode}/`);

    SparkApi.fetchPublicCategories(tCode)
      .then((result) => {
        let categories = [
          {
            label: "All",
            value: `/menu/${tCode}/`,
            categoryId: "All",
            isActive: category_id === undefined ? true : false,
            subCategories: [],
          },
        ];

        result.forEach((element) => {
          if (element.parent_id === null) {
            let category = {
              label: element.name,
              value: `/menu/${tCode}/c/${element.id}`,
              categoryId: element.id,
              isActive: category_id === element.id ? true : false,
              subCategories: [],
            };
            categories.push(category);
          }
        });

        categories.forEach((category) => {
          result.forEach((element) => {
            if (element.parent_id === category.categoryId) {
              category.subCategories.push({
                label: element.name,
                value: `/menu/${tCode}/c/${element.id}`,
                categoryId: element.id,
                isActive: category_id === element.id ? true : false,
              });
            }
          });
        });

        setNavMenus(categories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [location.pathname]);

  useEffect(() => {
    let updatedMenus = navMenus.map((menu) => ({
      ...menu,
      isActive: menu.categoryId === location.pathname.split("/")[4],
    }));
    setNavMenus(updatedMenus);
  }, [location.pathname]);

  const toggleMenuBar = (category) => {
    if (category.categoryId === "All") {
      window.location.href = allCategoryUrl;
    }
    let element = document.getElementById("basic-navbar-nav");
    element.classList.remove("show");
    PubSub.publish("category", category);
    let updatedMenus = navMenus.map((menu) => ({
      ...menu,
      isActive: menu.categoryId === category.categoryId,
    }));
    setNavMenus(updatedMenus);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownItemClick = (category) => {
    console.log(`Subcategories for ${category.label}:`, category.subCategories);
    setDropdownOpen(false);
  };

  return (
    <>
      {/* Hidden only on xs d-none d-sm-block */}
      <Navbar expand="lg navbar-expand-sm" className="bg-nav d-none d-sm-block">
        <Container>
          <Navbar.Brand href={allCategoryUrl}>
            <div
              className="brand-name"
              dangerouslySetInnerHTML={{ __html: companyTitle }}
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink
                className={`nav-link nav-link-category ${
                  navMenus[0]?.isActive ? "active-link" : ""
                }`}
                data-navname={navMenus[0]?.value}
                key={navMenus[0]?.value}
                to={navMenus[0]?.value}
              >
                {navMenus[0]?.label}
              </NavLink>
              {navMenus.length > 1 ? (
                <NavDropdownMenu
                  title="Categories"
                  className="custom-nav-dropdown-item"
                  show={dropdownOpen}
                  onToggle={handleDropdownToggle}
                >
                  {navMenus.slice(1).map((element) => (
                    <NavDropdown.Item
                      key={element.value}
                      className={`nav-link nav-link-category custom-nav-dropdown-item ${
                        element.isActive
                          ? "active-link custom-nav-dropdown-item"
                          : ""
                      }`}
                      onClick={() => handleDropdownItemClick(element)}
                    >
                      {!element.subCategories.length > 0 && (
                        <>
                          <NavLink to={element.value}>{element.label}</NavLink>
                        </>
                      )}
                      {element.subCategories.length > 0 && (
                        <DropdownSubmenu title={element.label}>
                          {element.subCategories.map((subCategory) => (
                            <NavDropdown.Item
                              key={subCategory.value}
                              onClick={() => handleDropdownItemClick(subCategory)}
                              className={`nav-link nav-link-category ${
                                subCategory.isActive ? "active-link" : ""
                              }`}
                            >
                              <NavLink to={subCategory.value}>
                                {subCategory.label}
                              </NavLink>
                            </NavDropdown.Item>
                          ))}
                        </DropdownSubmenu>
                      )}
                    </NavDropdown.Item>
                  ))}
                </NavDropdownMenu>
              ) : (
                navMenus.slice(1).map((element) => (
                  <NavLink
                    className={`nav-link nav-link-category ${
                      element.isActive ? "active-link" : ""
                    }`}
                    data-navname={element.value}
                    key={element.value}
                    to={element.value}
                  >
                    {element.label}
                  </NavLink>
                ))
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Visible only on xs d-block d-sm-none */}
      <Navbar expand="lg navbar-expand-sm" className="bg-nav d-block d-sm-none">
        <Container>
          <Navbar.Brand href={allCategoryUrl}>
            <div
              className="brand-name"
              dangerouslySetInnerHTML={{ __html: companyTitle }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {navMenus.map((element) => (
                <NavLink
                  className={`nav-link nav-link-category ${
                    element.isActive ? "active-link" : ""
                  }`}
                  data-navname={element.value}
                  key={element.value}
                  to={element.value}
                  onClick={() => toggleMenuBar(element)}
                >
                  {element.label}
                </NavLink>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
