import React, { useEffect, useRef, useState } from "react";
import SparkApi from "../api/SparkApi";
import * as constants from "../constants/CONSTANT";
import Image from "react-bootstrap/Image";
import ReactStars from "react-stars";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";
// import ReactImageMagnifyBasic from "./ReactImageMagnifyBasic";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const MenuItem = ({
  item,
  tenantCode,
  companyNumber,
  handleSocialIcon,
  companySetting,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [imageURL, setImageURL] = useState();
  // yamini 31-08-2023
  const [offerLabelColor, setOfferLabelColor] = useState();
  console.log("offerLabelColor", offerLabelColor);
  const vidRef = useRef();

  // useEffect(() => { vidRef.current.play(); },[]);
  const [videoStyle, setVideoStyle] = useState({
    display: "none",
    width: "100%",
  });
  //const [showSocial, setShowSocial] = useState(false);

  const [imageStyle, setImageStyle] = useState({
    display: "block",
    width: "100%",
  });
  const [playIcon, setPlayIcon] = useState({
    display: "block",
    position: "absolute",
    // right: "7px",
    left: "5px",
    top: "7px",
    cursor: "pointer",
    color: "white",
    zIndex: 9999,
  });
  const [pauseIcon, setPauseIcon] = useState({
    display: "none",
    position: "absolute",
    // right: "7px",
    left: "5px",
    top: "7px",
    cursor: "pointer",
    color: "white",
    zIndex: 9999,
  });
  const [isShow, setIsShow] = useState(false);

  const playVideo = () => {
    console.log("playVideo  :-  ");
    vidRef.current.play();
    setVideoStyle({ display: "block", width: "100%", objectFit: "cover" });
    setImageStyle({ display: "none", width: "100%" });
    setPauseIcon({
      display: "block",
      position: "absolute",
      // right: "7px",
      left: "5px",
      top: "7px",
      cursor: "pointer",
      color: "white",
      zIndex: 9999,
    });
    setPlayIcon({
      display: "none",
      position: "absolute",
      // right: "7px",
      left: "5px",
      top: "7px",
      cursor: "pointer",
      color: "white",
      zIndex: 9999,
    });
  };

  const pauseVideo = () => {
    setVideoStyle({ display: "none", width: "100%" });
    setImageStyle({ display: "block", width: "100%" });
    setPauseIcon({
      display: "none",
      position: "absolute",
      // right: "7px",
      left: "5px",
      top: "7px",
      cursor: "pointer",
      color: "#fff",
      zIndex: 9999,
    });
    setPlayIcon({
      display: "block",
      position: "absolute",
      // right: "7px",
      left: "5px",
      top: "7px",
      cursor: "pointer",
      color: "#fff",
      zIndex: 9999,
    });
  };

  useEffect(() => {
    async function init() {
      let image =
        item.files != null
          ? constants.PRODUCT_IMAGE_BASE_URL +
            "/images/" +
            tenantCode +
            "/" +
            item.id +
            "/" +
            item.files[0].Id +
            "." +
            item.files[0].fileType
          : `/NoProductFound.svg`;
      setImageURL(image);
      // if(item.filenames != null) {
      //     // console.log('item.filenames :-  ',item.filenames);
      //     if(item.filenames.includes('@#')) {
      //         item.filenames = '[' + item.filenames.replaceAll('@#', ',') + ']';
      //     item.filenames = JSON.parse(item.filenames);//item.filenames ? item.filenames.replaceAll('@#', ',') : '%PUBLIC_URL%/NoImage.png';
      //     }

      //     if(item.filenames[0].fileType === 'jpg' || item.filenames[0].fileType === 'jpeg' || item.filenames[0].fileType === 'png') {
      //         let result =  await SparkApi.downloadFiles(item.filenames[0].Id);
      //         item['body'] = window.URL.createObjectURL(result);
      //         item['fileType'] = item.filenames[0].fileType;

      //     }
      // } else {
      //     item['body'] = '';
      //     item['fileType'] = '';
      // }
      setIsShow(true);
      // Start yamini 31-08-2023
      const configurationSetting =
        await SparkApi.fetchCompanySetting(`${tenantCode}_card_setting`)
      const data = JSON.parse(configurationSetting.configuration);
      console.log("data", data);
      console.log("data.offerlabelcolor", data.offerlabelcolor);
      setOfferLabelColor(data.offerlabelcolor);
      // End yamini 31-08-2023
    }
    init();
  }, [item]);

  // const renderPreview = (type, body) => {
  //   // console.log('type  :-  ',type);
  //   body = `${constants.PRODUCT_IMAGE_BASE_URL}/images/${tenantCode}/` + body;
  //   switch (type) {
  //     case constants.PDF:
  //       return <i className="fa-solid fa-file-pdf fa-2xl file-thumbnail"></i>;
  //     case constants.DOC:
  //     case constants.DOCX:
  //       return <i className="fa-solid fa-file-word fa-2xl file-thumbnail"></i>;
  //     case constants.XLS:
  //     case constants.XLSX:
  //       return <i className="fa-solid fa-file-excel fa-2xl file-thumbnail"></i>;
  //     case constants.PNG:
  //     case constants.JPG:
  //     case constants.JPEG:
  //       return (
  //         <Image
  //           src={body}
  //           style={imageStyle}
  //           className="card-img-top menu-item"
  //         />
  //       );
  //     default:
  //       return <i className="fa-solid fa-file-file fa-2xl file-thumbnail"></i>;
  //   }
  // };

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const createHtml = (field, value) => {
    console.log("field  :-  ", field);
    console.log("value  :-  ", value);
    if (field.value === "rating") {
      return (
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={18}
          color2={"#dc3545"}
          value={4}
        />
      );
    } else if (
      field.value !== "rating" &&
      field.isPrimary &&
      field.sortOrder === 1
    ) {
      return (
        <Link to={"/menu/" + tenantCode + "/p/" + item.id} state={item}>
          <h5
            className="card-title float-left"
            style={{
              color: "rgb(79, 50, 103)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "12rem",
            }}
          >
            {value}
          </h5>
        </Link>
      );

      // return <h5 className="card-title float-left" style={{ color: "rgb(79, 50, 103)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "12rem"}}>
      //           {value}
      //         </h5>
    } else if (
      field.value !== "rating" &&
      field.isPrimary &&
      field.sortOrder === 2
    ) {
      return value;
    } else if (
      field.value !== "rating" &&
      field.label !== "formatted_description" &&
      field.isPrimary &&
      field.sortOrder === 3
    ) {
      return <p className="card-text product-descrption">{value}</p>;
      // } else if(field.value !== 'rating' && field.label === 'formatted_description' && field.isPrimary && field.sortOrder == 3) {
      //   return <div style={{ height: "3.1rem"}} className="formatted-desc" dangerouslySetInnerHTML={{ __html: value }} ></div>
    } else if (
      field.value !== "rating" &&
      field.label === "formatted_description"
    ) {
      return (
        <div style={{ height: "3.1rem" }} className="formatted-desc">
          {value ? parse(value) : ""}
        </div>
      );
    } else {
      return (
        <>
          <label className="fw-bold" style={{ textTransform: "uppercase" }}>
            {t(field.label)}
          </label>
          <p className="card-text">{value}&nbsp;</p>
        </>
      );
    }
  };
  // yamini 31-08-2023
  // const [ribbonColor , setRibbonColor] = useState({
  //   backgroundColor : item.offerlabelcolor
  // })

  return (
    <>
      <div className="card product-card">
        {/* yamini 31-08-2023 */}
        {item.showoffer ? (
          <div className="ribbon-2" style={{ background: offerLabelColor }}>
            {item.offerlabel}{" "}
          </div>
        ) : (
          ""
        )}
        {/* <div class="card-img-container"> */}
        {/* {renderPreview(item.fileType, item.files)} */}
        {/* </div> */}
        {/* <div
          class="card-img-container"
          style={{
            backgroundImage: `url(${constants.PRODUCT_IMAGE_BASE_URL}/images/${tenantCode}/${item.files})`,
          }}
        ></div> */}

        {/* <ReactImageMagnifyBasic></ReactImageMagnifyBasic> */}
        <div style={imageStyle}>
          {item.files != null ? (
            <Carousel
              controls={item.files != null && item.files.length > 1}
              interval={null}
              indicators={item.files != null && item.files.length > 1}
            >
              {item.files?.map((value, index) => {
                return (
                  <Carousel.Item key={index}>
                    <div
                      class="card-img-container"
                      style={{
                        backgroundImage: `url('${constants.PRODUCT_IMAGE_BASE_URL}/images/${tenantCode}/${item.id}/${value.Id}.${value.fileType}')`,
                      }}
                    ></div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <div
              className="card-img-container"
              style={{ backgroundImage: `url('/NoProductFound.svg')` }}
            ></div>
          )}
        </div>
        {item.videourl ? (
          <>
            <video
              className="menu-item card-img-container"
              style={videoStyle}
              src={item.videourl}
              ref={vidRef}
              muted
              autoPlay
              // playsinline
              loop
              preload="none"
            />
            <i
              className="fa-solid fa-circle-play fa-2x"
              style={playIcon}
              onClick={() => {
                playVideo();
              }}
            ></i>
            <i
              class="fa-solid fa-circle-pause fa-2x"
              style={pauseIcon}
              onClick={() => {
                pauseVideo();
              }}
            ></i>
          </>
        ) : (
          ""
        )}

        <div className="card-body pb-2">
          <div className="d-flex align-items justify-content-between">
            {/* {console.log('companySetting.primaryFields[0].label :-  ',companySetting.primaryFields)}; */}
            {companySetting?.primaryFields?.length > 0 ? (
              // <h5
              //     className="card-title float-left"
              //     style={{
              //       color: "rgb(79, 50, 103)",
              //       overflow: "hidden",
              //       textOverflow: "ellipsis",
              //       whiteSpace: "nowrap",
              //       width: "14rem",
              //     }}
              //   >
              //     {/* {console.log('companySetting.primaryFields[0].label :-  ',companySetting.primaryFields[0].label)} */}
              //     {item[companySetting.primaryFields[0].label]}
              //   </h5>
              <div>
                {createHtml(
                  companySetting.primaryFields[0],
                  item[companySetting.primaryFields[0].value]
                )}
              </div>
            ) : (
              ""
            )}
            {companySetting?.primaryFields?.length > 1 ? (
              <div style={{ lineHeight: "20px" }}>
                {createHtml(
                  companySetting.primaryFields[1],
                  item[companySetting.primaryFields[1].value]
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {companySetting?.primaryFields?.length > 2 ? (
              <span>
                {createHtml(
                  companySetting.primaryFields[2],
                  item[companySetting.primaryFields[2].value]
                )}
              </span>
            ) : (
              ""
            )}
          </div>
          {companySetting?.secondaryFields?.length ? (
            <div className="row pb-2">
              {companySetting.secondaryFields.map((element) => {
                return (
                  <div
                    key={element.id}
                    className="col-lg-6 col-sm-4 col-md-6 pt-4"
                  >
                    {/* <label className='fw-bold'>{element.value}</label>
              <p className="card-text">{item[element.value]}</p> */}
                    {createHtml(element, item[element.value])}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}

          <div className="d-flex align-items justify-content-between pt-4">
            <div style={{ textAlign: "center", cursor: "pointer" }}>
              {/* href={'https://wa.me/8619951193?text=Hello%0a'+ constants.PRODUCT_IMAGE_BASE_URL + '/images/' + tenantCode + '/' + item.files } */}

              {companyNumber ? (
                <>
                  {/* <a
                    target="_blank"
                    href={
                      "https://wa.me/" +
                      companyNumber.replaceAll("-", "") +
                      "?text=" +
                      constants.enquiryMessage
                        .replaceAll("{productname}", item.productname)
                        .replaceAll("{productimage}", imageURL)
                    }
                  >
                    <WhatsappIcon size={24} round />
                  </a> */}

                  <a
                    target="blank"
                    href={
                      "https://wa.me/" +
                      companyNumber.replaceAll("-", "") +
                      "?text=" +
                      constants.enquiryMessage
                        .replaceAll("{productname}", item.productname)
                        .replaceAll("{productimage}", imageURL)
                    }
                  >
                    <img
                      src="/whatsapp-whats-app-svgrepo-com.svg"
                      width="24px"
                    />
                  </a>

                  <div> {t("enquiry")}</div>
                </>
              ) : (
                ""
              )}
            </div>

            <div
              tabIndex={0}
              onClick={() => handleSocialIcon(item)}
              onBlur={() => handleSocialIcon(item)}
              style={{ cursor: "pointer", textAlign: "center" }}
            >
              {/* <i
                class="fa-solid fa-arrow-up-from-bracket"
                style={{
                  fontSize: "1.5rem",
                  color: "#47525dcc",
                  cursor: "pointer",
                }}
              ></i>
              <br />
              {t("share")} */}
              <img src="/share-svgrepo-com.svg" width="24px" />
              <div>{t("share")}</div>
              {item.showSocial && (
                <div
                  style={{
                    position: "absolute",
                    right: "0rem",
                    width: "12rem",
                    zIndex: "100",
                    borderRadius: "10px",
                    padding: ".5rem",
                    backgroundColor: "#f0f1f1",
                  }}
                >
                  <FacebookShareButton
                    url={
                      "https://digimenu.indicrm.io/menu/" +
                      tenantCode +
                      "/p/" +
                      item.id
                    }
                    quote={tenantCode}
                    hashtag={tenantCode}
                    className="mx-1"
                  >
                    <FacebookIcon size={24} round />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    // url={"https://digimenu.indicrm.io/menu/" + tenantCode}

                    url={
                      "https://digimenu.indicrm.io/menu/" +
                      tenantCode +
                      "/p/" +
                      item.id
                    }
                    quote={tenantCode}
                    hashtag={tenantCode}
                    className="mx-1"
                  >
                    <WhatsappIcon size={24} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    url={
                      "https://digimenu.indicrm.io/menu/" +
                      tenantCode +
                      "/p/" +
                      item.id
                    }
                    quote={tenantCode}
                    hashtag={tenantCode}
                    className="mx-1"
                  >
                    <LinkedinIcon size={24} round />
                  </LinkedinShareButton>
                  <TelegramShareButton
                    url={
                      "https://digimenu.indicrm.io/menu/" +
                      tenantCode +
                      "/p/" +
                      item.id
                    }
                    quote={tenantCode}
                    hashtag={tenantCode}
                    className="mx-1"
                  >
                    <TelegramIcon size={24} round />
                  </TelegramShareButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItem;
