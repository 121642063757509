import React, { useEffect, useState, useRef } from "react";
import Setting from "./Setting";
import SparkApi from "../api/SparkApi";


function CompanySettingContainer() {
  const [pageVariant, setPageVariant] = useState("widecard");
  const [configuration, setConfiguration] = useState([]);
  
  useEffect(() => {
    getCompanySettings();
  }, []);

  const settingRef = useRef(null);

  const handleMode = (mode) => {
    setPageVariant(mode);
  };

  const handleSave = () => {
    settingRef.current.saveSettings();
  };

  const getCompanySettings = async () => {
    const configurationSetting = await SparkApi.fetchCompanySettingByCompanyId();
    let setting = JSON.parse(configurationSetting.configuration);
    setPageVariant(setting.mode);
    setConfiguration(setting.fields);
  };

  return (
    <>
    <div className="row pt-2" style={{backgroundColor: 'white'}}>
        
        <div className="col-md-6 mt-2">
          <div className='d-flex'>
            <div style={{textAlign: 'left', paddingLeft : '10px'}}>
              <input type='radio' name='mode' onChange={() => handleMode('normal')} checked={pageVariant === 'normal'} />
              <label htmlFor="Normal" style={{marginLeft : "10px"}}>Normal</label>
            </div>
            <div style={{textAlign: 'left', paddingLeft : '10px'}}>
              <input type='radio' name='mode' onChange={() => handleMode('narrowcard')} checked={pageVariant === 'narrowcard'} />
              <label htmlFor="narrowcard" style={{marginLeft : "10px"}}>Narrowcard</label>
            </div>
            <div style={{textAlign: 'left', paddingLeft : '10px'}}>
              <input type='radio' name='mode' onChange={() => handleMode('widecard')} checked={pageVariant === 'widecard'} />
              <label htmlFor="widecard" style={{marginLeft : "10px"}}>Widecard</label>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-2" style={{textAlign: 'end'}}>
          <button
            className="btn btn-success p-1 me-2 btn-circle"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
      <Setting
        ref={settingRef}
        mode={pageVariant}
        configuration={configuration}
        onModeChange={handleMode}
      />
    </>
  );
}

export default CompanySettingContainer;




// import React, {useEffect, useState} from 'react';
// import Setting from './Setting';
// import SparkApi from '../api/SparkApi';

// function CompanySettingContainer() {
    
//     const [pageVariant, setPageVariant] = useState('widecard');

//     useEffect(() => {
//         getCompanySettings();
//     }, []);
    
//     const getPage = () => {
//         if(pageVariant == 'widecard') {
//             return <div><Setting mode={pageVariant}></Setting></div>
//         } else if(pageVariant == 'narrowcard') {
//             return <div><Setting mode={pageVariant}></Setting></div>
//         } else {
//             return <div><Setting mode={pageVariant}></Setting></div>
//         }
//     }

//     const handleMode = (mode) => {
//         console.log('handleMode called  :-  ',mode);
//         setPageVariant(mode);
//     }

//     const getCompanySettings = async () => {
//         const configurationSetting = await SparkApi.fetchCompanySettingByCompanyId();
//     let setting = JSON.parse(configurationSetting.configuration);
//     setPageVariant(setting.mode);
//         }

//       return (
//         <>
//             <div style={{    backgroundColor: 'white', textAlign: 'end'}}>
//             <div className='d-flex'>
//                     <div style={{textAlign: 'left', paddingLeft : '10px'}}>
//                     <input type='radio' name='mode' onChange={() => handleMode('normal')} checked={pageVariant == 'normal' ? true: false}/>
//                     <label htmlFor="Normal" style={{marginLeft : "10px"}}>Normal</label>
//                     </div>
//                     <div style={{textAlign: 'left', paddingLeft : '10px'}}>
//                     <input type='radio' name='mode' onChange={() => handleMode('narrowcard')} checked={pageVariant == 'narrowcard' ? true: false}/>
//                     <label htmlFor="narrowcard" style={{marginLeft : "10px"}}>Narrowcard</label>
//                     </div>
//                     <div style={{textAlign: 'left', paddingLeft : '10px'}}>
//                     <input type='radio' name='mode' onChange={() => handleMode('widecard')} checked={pageVariant == 'widecard' ? true: false}/>
//                     <label htmlFor="widecard" style={{marginLeft : "10px"}}>Widecard</label>
//                     </div>
//                </div>
//             </div>
//             {getPage()}
//         </>
//       )
// }

// export default CompanySettingContainer


