import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import PubSub from "pubsub-js";

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const CategoryList = () => {
    // const navigate = useNavigate();
    const [body, setBody] = useState([]);
    const [category, setCategory] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        fetchAllCategories();
        // fetchByIdCategories();
    }, []);


    const fetchAllCategories = async () => {
        const result = await SparkApi.fetchCategories();
        if (result) {
            console.log('result  :-  ',result);
            setBody(result);
        } else {
            setBody([]);
        }
    }


    // const fetchByIdCategories = async() =>{
    //     const result = await SparkApi.fetchIdCategories();
    //     if (result) {
    //       setBody(result);
    //     } else {
    //       setBody([]);
    //     }
    // }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = (row) => {
        setErrors([]);
        if(row.id){
            setCategory(row)
        }
        setShow(true);
    }



    // Create table headers consisting of 4 columns.
    const header = [
        {
            title: "Name",
            prop: "name",
            isFilterable: true,
            cell: (row) => (
                <Link onClick={() => handleShow(row)}>
                    {row.name}
                </Link>
            ),
        },
        // { title: "Slug", prop: "slug", isFilterable: true },
        { title: "Status", prop: "status", isFilterable: true, cell: (row) => (
            <>
                <input type="checkbox" checked={row.status} disabled={true}></input>
            </>
          ) },
        { title: "Description", prop: "description", cell: (row) => (
            <>
                <div className="text-truncate" style={{    width: '60rem'}}>{row.description}</div>
            </>
          ), isFilterable: true },
        {
            title: 'Actions', prop: 'id', cell: (row) => (
              <><Button className='btn-sm mx-2' onClick={() => handleShow(row)}><i className="fa-regular fa-pen-to-square"></i></Button>
                {/* <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i class="fa-regular fa-trash-can"></i></Button> */}
              </>
            )
          }
    ];

    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
        beforeSelect: " ",
    };


    const handleSubmit = async () => {
        console.log('category handle submit ', category)
        let result = {};
        console.log('errors  :-  ',validateValues(category));
        if(!validateValues(category)) {
            console.log('errors  :-  ',errors);
            if (category.id) {
                console.log('if edit call')
                result = await SparkApi.saveCategory(category);
                //console.log(result)
                if (result.success) {
                    setShow(false)
                    fetchAllCategories();
                    PubSub.publish("RECORD_SAVED_TOAST", {
                        title: "Record Saved",
                        message: "Record saved successfully",
                    });
                    setCategory('')
                }
            } else {
                console.log('if create call',category)
                result = await SparkApi.createCategory(category);
                console.log('result',result)
                if (result) {
                    setShow(false)
                    fetchAllCategories();
                    PubSub.publish("RECORD_SAVED_TOAST", {
                        title: "Record Created",
                        message: "Record Created successfully",
                    });
                    setCategory('')
                }
            }
        }
        
    }


    const handleChange = (e) => {
        console.log('[e.target.name]: e.target.value  :-  ',e.target.name, e.target.checked);
        if(e.target.name === 'status') {
            setCategory({ ...category, [e.target.name]: e.target.checked });
        } else{
            setCategory({ ...category, [e.target.name]: e.target.value });
        }
        console.log('category :-  ',category);
    };  

    const validateValues = (category) => {
        let allErrors = [];
        if (!category.hasOwnProperty('name') || !category.name.trim()) {
            allErrors.push("Name is required");
        }
        console.log('errors  :-  ',errors);
        setErrors(allErrors)
        return allErrors.length;
      };

    return (
        <Row className="g-0">

            <Col lg={12} className="px-4">
                <DatatableWrapper
                    body={body}
                    headers={header}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [5, 10, 15, 20],
                        },
                    }}
                >
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                            <PaginationOptions labels={labels} />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Button
                                className="btn-sm"
                                variant="outline-primary"
                                onClick={handleShow}
                            >
                                New Category
                            </Button>
                        </Col>
                    </Row>
                    {body ? (
                        <Table striped className="data-table" responsive="sm">
                            <TableHeader />
                            <TableBody />
                        </Table>
                    ) : (
                        <ShimmerTable row={10} col={8} />
                    )}

                    <Pagination />
                </DatatableWrapper>
            </Col>
            <Col lg={2}></Col>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-3" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter Name"
                                    value={category.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        
                        <Col lg={6}>
                            <Form.Group className="mx-3 pt-4" controlId="formBasicStatus">
                                <Form.Label
                                    
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                  Status
                                </Form.Label>
                                {/* <Form.Control
                                    type="checkbox"
                                    name="status"
                                   
                                    value={category.status}
                                    onChange={handleChange}
                                >
                                </Form.Control> */}
                                <Form.Check type="checkbox"  name="status" checked={category.status} onChange={handleChange} />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>

                            <Form.Group className="mx-3" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Description
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    placeholder="Enter Category Description"
                                    value={category.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <div>
                                {errors.length !== 0 ? errors.map((error) => {
                                    return <p className="text-danger">{error}</p>
                                }) : ''}
                            </div>
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Row>


    );
};
export default CategoryList;

