import React, { useState } from "react";

const ThumbnailImageContainer = ({ productData, constants, tCode }) => {
  const [startIndex, setStartIndex] = useState(0);

  const handleNextClick = () => {
    setStartIndex((prevIndex) =>
      prevIndex + 1 < productData?.files?.length ? prevIndex + 1 : 0
    );
  };

  const handlePrevClick = () => {
    setStartIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : productData?.files?.length - 1
    );
  };

  return (
    <>
      <div className="thumbnail-container d-flex justify-content-center mt-3">
        {startIndex > 0 && (
          <i
            className="fa fa-angle-left pt-5"
            style={{ cursor: "pointer" }}
            onClick={handlePrevClick}
          ></i>
        )}
        {productData?.files?.slice(startIndex, startIndex + 3).map((value, index) => (
          <img
            key={index}
            src={`${constants.PRODUCT_IMAGE_BASE_URL}/images/${tCode}/${productData.id}/${value.Id}.${value.fileType}`}
            alt={`Thumbnail ${index}`}
            className={`img-thumbnail mx-2 ${index === 0 ? "active" : ""}`}
            style={{ width: "100px", height: "100px" }} 
            onClick={() => {
              const carousel = document.getElementById("carouselExampleCaptions");
              const newIndex = startIndex + index;

              carousel
                .querySelector(".carousel-indicators li.active")
                .classList.remove("active");
              carousel
                .querySelector(
                  `.carousel-indicators li[data-bs-slide-to="${newIndex}"]`
                )
                .classList.add("active");
              carousel
                .querySelector(".carousel-inner .carousel-item.active")
                .classList.remove("active");
              carousel
                .querySelector(
                  `.carousel-inner .carousel-item:nth-child(${newIndex + 1})`
                )
                .classList.add("active");
            }}
          />
        ))}
        {startIndex + 3 < productData?.files?.length && (
          <i
            className="fa fa-angle-right pt-5"
            style={{ cursor: "pointer" }}
            onClick={handleNextClick}
          ></i>
        )}
      </div>
    </>
  );
};

export default ThumbnailImageContainer;