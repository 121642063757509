import React, { useState, useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import jwt_decode from "jwt-decode";

const QrCode = () => {
  const [qrcodeURL, setQrCodeURL] = useState("");
  const [userInfo, setUserInfo] = useState({});
 
  useEffect(() => {
    try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        console.log("user",user);
        setUserInfo(user);
        setQrCodeURL(
          `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=https://digimenu.indicrm.io/menu/${user.tenantcode}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  //

  const printIt = () => {
    
    let url = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=https://digimenu.indicrm.io/menu/${userInfo.tenantcode}`  
    var mywindow = window.open();
    var is_chrome = Boolean(mywindow.chrome);
    mywindow.document.write(`
    <html>
    <head>
        <style>
            @media print {
                
                .info-box {
                    background-color: black;
                    color: white;
                    text-align:center;
                    width:300px;
                    height:48px;
                    position: relative;
                    top: -22px;
                    -webkit-print-color-adjust: exact;
                }
            }
        </style>
    </head>
    <body>
        <div class="center-content" align="center">
            <img src=${url} alt="QR Code" class="qr-code" />
            <div class="info-box">
                <h1>${userInfo.companyname}</h1>
            </div>
        </div>
    </body>
    </html>
  
`);
{/* <div style="text-align: center;">
<img src=${url} alt="QR Code" style="-webkit-print-color-adjust: exact;" />
<div class="print-bg" style="background-color:black; color: blue;  margin-top: 10px; height : 3%  ">
    <b>${userInfo.companyname}</b>
</div>
</div> */}

   if (is_chrome) {
     setTimeout(function() { // wait until all resources loaded 
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        mywindow.print(); // change window to winPrint
        mywindow.close(); // change window to winPrint
     }, 250);
   } else {
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        mywindow.print();
        mywindow.close();
   } 
  };

  return (
    <Container>
      <Col lg={5} className="mt-3 py-2" align="center" style={{backgroundColor:"white"}}>
      <div className="me-3" >
          <button  class="btn-sm btn btn-outline-primary" style={{float: "right"}}  onClick={printIt} >
            <i className="fa fa-print"></i>
          </button>
      </div>
       
        <div id="qr-code" className="mt-5 me-5">
          <img src={qrcodeURL} alt="QR Code" />
        </div>
        <div 
          style={{
            backgroundColor: "black",
            width:"300px",
            color: "white",
             marginRight: "41px",
            // borderRadius: "20px",
            position: "relative",
            top: "-1px",
          }}
        >
          <h1>{userInfo.companyname}</h1>
        </div>
        
      </Col>
    </Container>
  );
};

export default QrCode;