/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CityState from "../constants/CityState.json";
import * as constants from '../constants/CONSTANT';
import jwt_decode from "jwt-decode";


const LeadEdit = () => {
    const [validated, setValidated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [optionContact, setOptionContact] = useState({});
    const [selectedLeadSource, setSelectedLeadSource] = useState({});
    const [selectStatus, setSelectStatus] = useState({});
    const [selectIndustry, setSelectIndustry] = useState({});
    const [selectSalutation, setSalutation] = useState({});
    const [lostReason, setLostReason] = useState(false);
    const [selectUser, setSelectUser] = useState({});
    const [name, setName] = useState("");
    const [state, setState] = useState({});
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [option, setoption] = useState();
    const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));

    //const [rating,setRating]=useState([{}]);
    const industryValue = [
        { value: "", label: "--None--" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "Apparel", label: "Apparel" },
        { value: "Banking", label: "Banking" },
        { value: "Chemicals", label: "Chemicals" },
        { value: "Communications", label: "Communications" },
    ];
    const salutationValue = [
        { value: "", label: "--None--" },
        { value: "Mr.", label: "Mr." },
        { value: "Ms.", label: "Ms." },
        { value: "Dr.", label: "Dr." },
        { value: "Mrs..", label: "Mrs.." },
        { value: "Prof.", label: "Prof.." },
    ]
    const roleValue = [
        { value: "", label: "--None--" },
        { value: "ADMIN", label: "Admin" },
        { value: "USER", label: "User" },
        // {value:"Dr.",label:"Dr."},
        // {value:"Mrs..",label:"Mrs.."},
        // {value:"Prof.",label:"Prof.."},
    ]
    const leadSource = [
        { value: "Web", label: "Web" },
        { value: "Phone Enquiry", label: "Phone Enquiry" },
        { value: "Partner Referral", label: "Partner Referral" },
        { value: "Purchased List", label: "Purchased List" },
        { value: "Other", label: "Other" }
    ];
    const leadStatus = leadStatusArray;
    const [show, setShow] = React.useState(false);

    const [lead, setLead] = useState(location.state ? location.state : {});
   
    // //console.log(:)
    let userInfo;
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));
        //console.log('userInfo', userInfo);
        
        

        if (location?.state) {
            //console.log(location.state)
            let salutation = salutationValue.filter(salutation => salutation.value === location.state.salutation)
            let source = leadSource.filter(source => source.value === location.state.leadsource)
            let industry = industryValue.filter(industry => industry.value === location.state.industry)
            let status = leadStatus.filter(status => status.value === location.state.leadstatus)

            //console.log('source',leadStatus)
            setSalutation(salutation[0]);
            setSelectedLeadSource(source[0]);
            setSelectIndustry(industry[0]);
            setSelectStatus(status[0]);
            setLostReason(location.state.iswon === false);

            //console.log('Lead Data ', location.state)
            if (lead.id) {
                let temp = {}
                temp.value = location.state.ownerid;
                temp.label = location.state.ownername;
                setoption(temp);
            }else{
                let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username;
        setoption(temp);
        lead.ownername = userInfo.username;
        lead.ownerid = userInfo.id;
            }

        }else{
            
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username;
        setoption(temp);
        lead.ownername = userInfo.username;
        lead.ownerid = userInfo.id;
            
        }

        async function init() {
            const fetchUser = await SparkApi.fetchUsers();
            //console.log("fetchUser=>" + JSON.stringify(fetchUser))
            let usr = []
            fetchUser.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.username;
                //console.log(" obj.label >" + obj.label)
                usr.push(obj);
            })
            setSelectUser(usr);

            ////console.log('CityState',CityState)
            let st = [];

            CityState.map((item) => {
                ////console.log(item.state,item.id);
                var obj = {};
                obj.value = item.state;
                obj.label = item.state;
                ////console.log(" obj.label >"+ obj.label)
                st.push(obj);

            });
            let finalStates = {};
            st = st.filter(function(currentObject) {
                if (currentObject.value in finalStates) {
                    return false;
                } else {
                    finalStates[currentObject.value] = true;
                    return true;
                }
            });
            console.log('st:', st);
            setState(st);
        }

        init();


    }, []);

    const handleState = (e) => {
        let filteredCities = [];
        CityState.forEach(function (obj) {
            if (obj.state === e.value) {
                filteredCities.push({
                    label: obj.name,
                    value: obj.name
                })
            }
        });
        setCities(filteredCities);
        setLead({ ...lead, 'state': e.value });
    }

    const handleSelectListChange = (value, name) => {
        //console.log('contact:', lead);
        setLead({ ...lead, [name]: value.value });
        setSelectedCity(value.value);

    }
    //Access all the value input values 
    const handleChange = (e) => {
        //console.log("e.target : ", e.target)
        let temp = { ...lead }
        //console.log("temp : ", temp)
        setLead({ ...lead, [e.target.name]: e.target.value });

        if(e.target.name === 'leadstatus'){
            leadStatus.map((status) => {
                if(status.label === e.target.value){
                    if(status.is_lost === true){
                        setLostReason(true);
                        return;
                    }else{
                        setLostReason(false);
                        return;
                    }
                }
            });
            
        }
        //console.log("lead:", lead);

    };

    const handleRoleChange = (e) => {
        //console.log('event', e)
        setoption(e)
        setLead({ ...lead, 'ownerid': e.value, ownername: e.label });
    }


    const handleSubmit = async (e) => {
        //console.log('handle submit call', lead)
        e.preventDefault();

        if (checkRequredFields()) {
            setValidated(true);
            return;
        }



        //========= Logic to perform Create or Edit ======
        let result = {};

        let iswon = null;

        leadStatus.map((status) => {
            console.log('status:', status);
            console.log('lead.leadstatus:', lead.leadstatus);
            if(status.label === lead.leadstatus){
                if(status.is_converted === true){
                    iswon = true;
                }else if(status.is_lost === true){
                    iswon = false;
                }
            }
        });

        lead.iswon = iswon;

        

        if (lead.id) {
            console.log("lead.id: ", lead);
            //console.log("lead: ", JSON.stringify(lead));
            result = await SparkApi.updateLead(lead);
            //console.log("result ===>", result);
            if (result.success) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/leads/${lead.id}`, { state: lead });
            }
        } else {

            result = await SparkApi.createLead(lead);
            //console.log('result', result)
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/leads/${result.id}`, { state: result });
            }
        }
    };

    const checkRequredFields = () => {
        if (!option) {
            //console.log('if call');
            setShow(true);
            document.querySelector(".username").style.border = "1px solid red";

        }
        if ((lead.firstname && lead.firstname.trim() !== '') && (lead.lastname && lead.lastname.trim() !== '') && lead.phone && lead.leadstatus) {
            return false;
        }
        return true;
    }

    const handleCancel = () => {
        navigate("/leads/", { state: lead });
    };

    return (
        <Container className="view-form">
            <Row className="view-form">
            <Col></Col>
         <Col lg={8} className="pb-1 pt-2">
       <Link className="nav-link" to="/leads">
         Home <i className="fa-solid fa-chevron-right"></i> <div style={{color: "#23468c", display : "inline"}}>Leads</div>
       </Link>
     </Col>
     <Col></Col>
           </Row>
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={9}>
                                {
                                    name === "" ? <h6>Create Lead</h6> : <h6> Edit Lead</h6>
                                }

                                <h5>{name}</h5>
                            </Col>
                            <Col lg={3} >
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row className="ibs-edit-form mb-4" lg={12}>

                            <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                                Please Select Assign Staff
                            </Alert>


                            <Col lg={2}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Salutation
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="Select Salutation"
                                        name="salutation"
                                        value={lead.salutation}
                                        onChange={handleChange}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                        <option value="Dr">Dr.</option>
                                        <option value="Prof">Prof.</option>
                                    </Form.Select>

                                </Form.Group>

                            </Col>
                            <Col lg={4}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        First Name
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        required={true}
                                        placeholder="Enter First Name"
                                        value={lead.firstname}

                                        onChange={(e) => handleChange(e)}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide First Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        required={true}
                                        type="text"
                                        name="lastname"
                                        placeholder="Enter LastName"
                                        value={lead.lastname}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter LastName.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col lg={6} >
                                <Form.Group>
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Phone
                                    </Form.Label>
                                    <Form.Control

                                        type="number"
                                        name="phone"
                                        required
                                        placeholder="Enter Phone"
                                        value={lead.phone}
                                        //defaultValue={['Asif']}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>



                            <Col lg={6}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Company
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="company"
                                        placeholder="Enter Company"
                                        value={lead.company}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Company.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control

                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={lead.email}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Title
                                    </Form.Label>
                                    {/* <Form.Control

                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                        value={lead.title}
                                        onChange={(e) => handleChange(e)}
                                    /> */}

                                    <Form.Select value={lead.title} name="title" onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        <option value="CEO">CEO</option>
                                        <option value="Director">Director</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Owner">Owner</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Executive">Executive</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Title.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Website
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="website"
                                        placeholder="Enter Website"
                                        value={lead.website}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Fax.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>




                            <Col lg={6} >

                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLeadSource"
                                    >
                                        Source
                                    </Form.Label>

                                    <Form.Select aria-label="Enter status" value={lead.leadsource} name="leadsource" onChange={handleChange}>
                                        <option value="">--Select-Source--</option>
                                        <option value="Phone">Phone</option>
                                        <option value="Partner Referral">Partner Referral</option>
                                        <option value="BNI">BNI</option>
                                        <option value="Purchased List">Purchased List</option>
                                        <option value="Web">Web</option>
                                        <option value="Email">Email</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Other">Other</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter  Lead Source.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Assigned User
                                    </Form.Label>
                                    <Select
                                        required
                                        value={option}
                                        className="custom-select username"
                                        onChange={(e) => handleRoleChange(e)}
                                        options={selectUser}

                                    //value={selectSalutation}
                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Select-Role.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Industry
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Industry" value={lead.industry} name="industry" onChange={handleChange}>
                                        <option value="">--Select-Industry--</option>
                                        <option value="Agriculture">Agriculture</option>
                                        <option value="Apparel">Apparel</option>
                                        <option value="Banking">Banking</option>
                                        <option value="Biotechnology">Biotechnology</option>
                                        <option value="Chemicals">Chemicals</option>
                                        <option value="Communications">Communications</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Consulting">Consulting</option>
                                        <option value="Education">Education</option>
                                        <option value="Electronics">Electronics</option>
                                        <option value="Energy">Energy</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Environmental">Environmental</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Food and Beverage">Food and Beverage</option>
                                        <option value="Government">Government</option>
                                        <option value="Healthcare">Healthcare</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Legal">Legal</option>
                                        <option value="Machinery">Machinery</option>
                                        <option value="Manufacturing">Manufacturing</option>
                                        <option value="Media">Media</option>
                                        <option value="Non Profit">Non Profit (NGO)</option>
                                        <option value="Recreation">Recreation</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Technology">Technology</option>
                                        <option value="Telecommunications">Telecommunications</option>
                                        <option value="Transportation">Transportation</option>
                                        <option value="Utilities">Utilities</option>
                                        <option value="Utilities">Other</option>
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        Enter Industry.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                           
                            
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Model
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentmodel" onChange={handleChange} value={lead.paymentmodel}>
                                        <option value="">--Select Payment Model--</option>
                                        
                                            <option value="Subscription">
                                                Subscription
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                       


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Terms
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentterms" onChange={handleChange} value={lead.paymentterms}>
                                        <option value="">--Select Terms--</option>
                                        <option value="12">
                                                12 Months
                                            </option>
                                            <option value="24">
                                                24 Months
                                            </option>
                                            <option value="One Time">
                                                One Time
                                            </option>
                                            <option value="One Time with Yearly Renewal">
                                                One Time with Yearly Renewal
                                            </option>


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Status
                                    </Form.Label>
                                    <Form.Select required aria-label="Enter Status" name="leadstatus" onChange={handleChange} value={lead.leadstatus}>
                                        <option value="">--Select-Status--</option>
                                        {leadStatusArray.map((item, index) => (
                                            <option value={item.label} key={index}>
                                                {item.label}
                                            </option>
                                        ))}


                                    </Form.Select>


                                    <Form.Control.Feedback type="invalid">
                                        Enter LeadStatus.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Expected Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        placeholder="Enter Expected Amount"
                                        value={lead.amount}
                                        onChange={(e) => handleChange(e)}
                                    />


                                    <Form.Control.Feedback type="invalid">
                                        Enter LeadStatus.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {lostReason && 
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Lost Reason
                                    </Form.Label>
                                    <Form.Control
                                                as="textarea"
                                                name="lostreason"
                                                required
                                                placeholder="Enter lost reason"
                                                value={lead.lostreason}
                                                onChange={handleChange}
                                            />


                                   
                                </Form.Group>
                            </Col>
                        }
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                                as="textarea"
                                                name="description"
                                                
                                                placeholder="Enter Description"
                                                value={lead.description}
                                                onChange={handleChange}
                                            />


                               
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="section-header">
                                ADDRESS INFORMATION
                            </Col>


                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        State
                                    </Form.Label>
                                    <Select
                                        placeholder="State"
                                        defaultValue={{ label: lead.state, value: lead.state }}
                                        onChange={handleState}
                                        options={state}

                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter State.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}  >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        City
                                    </Form.Label>
                                    <Select options={cities}
                                        placeholder="Enter City"
                                        onChange={(e) => { handleSelectListChange(e, 'city') }}
                                        name="city"
                                        defaultValue={{ label: lead.city, value: lead.city }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter City.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Street
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="street"
                                        placeholder="Enter Street"
                                        value={lead.street}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Street.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Zip / PostalCode
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="zipcode"
                                        placeholder="Enter PostalCode"
                                        value={lead.zipcode}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter PostalCode.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} className="pb-3">
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Country
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="country"
                                        placeholder="Enter Country"
                                        value={lead.country}
                                        defaultValue="India"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Country.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col className="mt-5"></Col>

                        </Row>

                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
export default LeadEdit