import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import PubSub from "pubsub-js";
import Select from "react-select";

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const ItemList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    const [item, setItem] = useState({});
    const [option, setoption] = useState();
    const [selectedUser, setSelectedUser] = useState('');
    const [optionUsers, setOptionUsers] = useState([]);

    useEffect(() => {
        fetchAllCategories()
        fetchAllItems();

    }, []);

    const fetchAllCategories = async () => {
        const result = await SparkApi.fetchCategories();
        if (result) {
            var ar = [];
            result.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.name + '  ' + item.name_locale;
                ar.push(obj);
            });

            // Now, "ar" contains the desired array of objects.

            //console.log('ar', ar)
            setOptionUsers(ar);
        } else {
            setOptionUsers([]);
        }
    }
    const fetchAllItems = async () => {
        const result = await SparkApi.fetchItems();
        if (result) {

            setBody(result);
        } else {
            setBody([]);
        }
    }


    // const fetchByIdCategories = async() =>{
    //     const result = await SparkApi.fetchIdCategories();
    //     if (result) {
    //       setBody(result);
    //     } else {
    //       setBody([]);
    //     }
    // }

    const handleUsers = (event) => {
        console.log(event)
        setoption(event)
        setSelectedUser(event)
        //console.log('eventRec', eventRec)
        setItem({ ...item, categoryid: event.value, categoryname: event.label });
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = (row) => {
        console.log('row',row)
        if (row.id) {
            
            let tempValue = {};
            tempValue.value = row.categoryid;
            tempValue.label = row.categoryname;
            setItem(row)
            setoption(tempValue);

        }
        setShow(true);
    }


    // Create table headers consisting of 4 columns.
    const header = [
        {
            title: "Name",
            prop: "name",
            isFilterable: true,
            cell: (row) => (
                <Link onClick={() => handleShow(row)}>
                    {row.name}
                </Link>
            ),
        },
        { title: "नाम", prop: "name_locale", isFilterable: true },
        { title: "price", prop: "price", isFilterable: true },
        { title: "Measurement", prop: "measurement", isFilterable: true },

        { title: "Category Name", prop: "categoryname", isFilterable: true },
        { title: "Sub Category", prop: "subcategory", isFilterable: true },

        { title: "Description", prop: "discription", isFilterable: true },

        {
            title: 'Actions', prop: 'id', cell: (row) => (
                <><Button className='btn-sm mx-2' onClick={() => handleShow(row)}><i className="fa-regular fa-pen-to-square"></i></Button>
                    {/* <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i class="fa-regular fa-trash-can"></i></Button> */}
                </>
            )
        }
    ];

    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
        beforeSelect: " ",
    };


    const handleSubmit = async () => {
        console.log('category handle submit ', item)
        let result = {};
        if (item.id) {
            console.log('if edit call', item)
            result = await SparkApi.saveItem(item);
            console.log(result)
            if (result.success) {
                setShow(false)
                fetchAllItems();
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record saved successfully",
                });
                setItem('')
            }
        } else {
            console.log('if create call', item)
            result = await SparkApi.createItem(item);
            console.log('result', result)
            if (result) {
                setShow(false)
                fetchAllItems();
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Created",
                    message: "Record Created successfully",
                });
                setItem('')
                setoption('');
            }
        }
    }


    const handleChange = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value });
    };

    console.log('item', item)

    return (
        <Row className="g-0">

            <Col lg={12} className="px-4">
                <DatatableWrapper
                    body={body}
                    headers={header}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [5, 10, 15, 20],
                        },
                    }}
                >
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                            <PaginationOptions labels={labels} />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Button
                                className="btn-sm"
                                variant="outline-primary"
                                onClick={handleShow}
                            >
                                New Item
                            </Button>
                        </Col>
                    </Row>
                    {body ? (
                        <Table striped className="data-table" responsive="sm">
                            <TableHeader />
                            <TableBody />
                        </Table>
                    ) : (
                        <ShimmerTable row={10} col={8} />
                    )}

                    <Pagination />
                </DatatableWrapper>
            </Col>
            <Col lg={2}></Col>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter Name"
                                    value={item.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Item Name (हिन्दी)
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name_locale"
                                    placeholder="नाम भरे "
                                    value={item.name_locale}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>



                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Category
                                </Form.Label>
                                <Select
                                    name="categoryid"
                                    value={option}
                                    className="custom-select username"
                                    onChange={handleUsers}
                                    options={optionUsers}
                                    getOptionValue={(option) => option.value}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Unit
                                </Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    name="measurement"
                                    placeholder="Enter Measurement"
                                    value={item.measurement}
                                    onChange={handleChange}
                                /> */}

                                <Form.Select name="measurement" value={item.measurement} onChange={handleChange}>
                                    <option value="">Select Type</option>
                                    <option value="Kg">1 Kg</option>
                                    <option value="Leter">1 Litre</option>
                                    <option value="Piece">1 Piece</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Price
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="price"
                                    placeholder="Price"
                                    value={item.price}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>


                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label className="form-view-label" htmlFor="formBasicName">
                                    Type
                                </Form.Label>
                                <Form.Select name="type" value={item.subcategory} onChange={handleChange}>
                                    <option>-Select-</option>
                                    <option value="Veg">Veg</option>
                                    <option value="Non Veg">Non Veg"</option>


                                </Form.Select>
                            </Form.Group>

                        </Col>




                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label className="form-view-label" htmlFor="formBasicName">
                                    Sub Category
                                </Form.Label>
                                <Form.Select name="subcategory" value={item.subcategory} onChange={handleChange}>
                                    <option >Select Type</option>
                                    {item.categoryname === 'Bread  ब्रेड' && (
                                        <>
                                            <option value="Plain">Plain</option>
                                            <option value="Stuff">Stuff</option>
                                        </>
                                    )}
                                    {item.categoryname === 'Dessert (Sweets)  डिजर्ट स्वीट्स' && (
                                        <>
                                            <option value="Sweet">Sweet</option>
                                            <option value="Ice-Cream">Ice-Cream</option>
                                        </>
                                    )}
                                    {item.categoryname === 'Beverages (Drinks)  बवेरेगेस ड्रिंक्स' && (
                                        <>
                                            <option value="Alcoholic">Alcoholic</option>
                                            <option value="Non Alcoholic">Non Alcoholic</option>
                                        </>
                                    )}
                                    {(item.categoryname === 'Fast Food  फ़ास्ट फ़ूड' || item.categoryname === 'Stall  स्टाल' || item.categoryname === 'Veg Dish  वेज डिश') && (
                                        <>
                                            <option value="Veg">Veg</option>
                                            <option value="Non-Veg">Non-Veg</option>
                                        </>
                                    )}

                                </Form.Select>
                            </Form.Group>

                        </Col>

                        <Col lg={6}>

                            <Form.Group className="mx-3 my-2" controlId="formBasicName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicName"
                                >
                                    Description
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="discription"
                                    placeholder="Enter Category Discription"
                                    value={item.discription}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Row>


    );
};
export default ItemList;

