import React from "react";
import { Col, Container, Row } from "react-bootstrap";
const Support = () => {
  return (
    <>
      <Container>
        <Row className="mt-4 ">
          <Col lg={5}>
            <div className="card ps-3">
              
                <a href="https://indicrm.io" target="blank">
                  <img
                    src="/indicrm.png"
                    style={{ width: "140px", paddingTop: ".5rem" }}
                    alt=""
                  />
                </a>
              
              <div >
                <ul className="list-unstyled support">
                <li >
                    <span >
                        <i class="fa-solid fa-globe"></i>
                    </span>
                    <span className=" ps-2">
                    <a href="https://indicrm.io" target="blank">www.indicrm.io</a>
                    </span>
                  </li>
                  <li >
                    <span className="elementor-icon-list-icon">
                      <i className="fa fa-phone" ></i>
                    </span>
                    <span className=" ps-2">
                      <a href="tel:+91-9521347078">+91-9521347078</a>
                    </span>
                  </li>
                  <li >
                    <span className="elementor-icon-list-icon">
                        <i className="fa-solid fa-location-dot" ></i>
                    </span>
                    <span className=" ps-3">
                      <a href="https://www.google.com/maps/place/iBirds+Software+Services+Pvt.+Ltd./@26.4702147,74.6307018,17z/data=!3m1!4b1!4m6!3m5!1s0x396be6540d33f475:0x72884a3dc10a061b!8m2!3d26.4702099!4d74.6332767!16s%2Fg%2F11fylr8dy1?entry=ttu">iBirds Software Services 5th floor, <br/>Srinath Mall Ajmer, Rajasthan 305001, India</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>


      
    </>

    
  );
};

export default Support;
