import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import PubSub from "pubsub-js";
import SparkApi from "../api/SparkApi";
import { getCountryListMap } from "country-flags-dial-code";

const EditProfile = ({ userId }) => {
  const fileInputRef = useRef();
  // const [profile, setProfile] = useState({});
  const [body, setBody] = useState();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({ password: "", confirmpassword: "" });
  const [validated, setValidated] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [file, setFile] = useState();
  const [options1, setOptions1] = useState([]);
  const [showList, setShowList] = useState(false);
  // const formattedPhoneNumber = `${profile.countryCode}-${profile.phone}`;
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    countryCode: "",
  });
  // const options2 = [];

  useEffect(() => {
    newMethod();
  }, []);

  const newMethod = () => {
    async function init() {
      let result = await SparkApi.getLoginUserData();
      setProfile(result);
      console.log("result:", result);
      setBody(localStorage.getItem("myimage"));
      // let files = await SparkApi.fetchFiles(result.id);
      // setFile(files.id)
      // //setSelectedFiles(files);
      // //console.log('FILES', files[0])
      // if (files && files.length > 0) {
      //   let file = files[0];
      //   let downloadresult = await SparkApi.downloadFiles(file.id);
      //   //console.log('result:', downloadresult);
      //   file.body = window.URL.createObjectURL(downloadresult);
      //   //console.log(file.body);
      //   setBody(file.body);
      // }
      if (result && result.phone) {
        var [code, number] = result.phone.split("-");
        console.log("code", code);
        setProfile((prevProfile) => ({
          ...prevProfile,
          countryCode: code,
          phone: number,
        }));
      } else {
        console.error("Profile phone is undefined or empty.");
      }
    }
    init();
  };

  useEffect(() => {
    const countries1 = getCountryListMap();
    console.log(" countries1 1 dhj ", countries1);
    let x = Array.from(Object.values(countries1));
    console.log(x[0], "x");
    setOptions1(x);
  }, []);

  useEffect(() => {
    console.log(options1, "options", options1.length);
    if (options1?.length > 0) setShowList(true);
  }, [options1]);

  const handlePasswordOnchange = (e) => {
    //console.log(e.target.value)
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const selectedIndex = event.target.selectedIndex;
    const selectedDialCode =
      selectedIndex >= 0 ? event.target.options[selectedIndex].value : "";
    if (name === "phone") {
      const regex = /^[0-9\b]+$/;
      if (
        event.target.value === "" ||
        (regex.test(event.target.value) && event.target.value.length <= 10)
      ) {
        setProfile((prevProfile) => ({
          ...prevProfile,
          [name]: `${selectedDialCode}${event.target.value}`,
        }));
      }
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    console.log("handleSubmit");
    event.preventDefault();
    //console.log('selected file', selectedFiles)
    profile.phone = profile.countryCode + "-" + String(profile.phone);
    console.log(" profile.phone", profile.phone);
    let result = {};
    if (selectedFiles === null) {
      //console.log('selected file');
      result = await SparkApi.saveUser(profile);
      //console.log('result save', result)
      if (result.success) {
        newMethod();
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
      } else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Record Save Error",
          message: result.errors,
        });
      }
    } else {
      let result = await SparkApi.saveStaffMemberEditProfile(
        profile.id,
        selectedFiles,
        JSON.stringify(JSON.stringify(profile))
      );
      localStorage.setItem("myimage", body);
      //console.log('file update', result);
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Saved",
        message: " Record update successfully",
      });
    }
  };

  const handlePhotoUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles(event.target.files[0]);
  };

  const handleClose = () => setShow(false);

  const handleChangeSubmit = async (e) => {
    //console.log('user', user);
    setShow(true);
    e.preventDefault();
    //console.log('userId', userId);
    //console.log('user', user)
    if (user.password === user.confirmpassword && user.password !== "") {
      const result = await SparkApi.updateUser(user);
      setShow(false);
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Saved",
        message: "Password updated successfully",
      });
      //console.log('result', result);
    } else {
      //console.log('Password is null, please enter a valid password.');
    }
  };

  console.log("setProfile", profile);

  return (
    <Container>
      <Row className="pt-4">
        <Col lg={1}></Col>
        <Col lg={3}>
          <Card>
            <Card.Body className="text-center">
              <Card.Title style={{ textAlign: "center" }}>
                {profile.firstname} {profile.lastname}
              </Card.Title>
              <Image
                variant="top"
                src={body}
                className="rounded-circle"
                thumbnail
                style={{ width: "200px" }}
              ></Image>
              <br />
              <Button
                className="btn my-3 "
                variant="secondary"
                style={{ width: "100%", display: "block" }}
                onClick={() => fileInputRef.current.click()}
              >
                Image Upload
              </Button>
              <Button
                className="btn"
                style={{ width: "100%", display: "block" }}
                variant="danger"
                onClick={handleChangeSubmit}
              >
                Change Password
              </Button>
              <input
                onChange={handlePhotoUpload}
                name="profilephotourl"
                ref={fileInputRef}
                type="file"
                hidden
              />
            </Card.Body>
          </Card>
        </Col>

        <Col style={{ backgroundColor: "#fff" }} lg={7}>
          <Row className="view-form-header align-items-center">
            <Col lg={6}>Edit Profile</Col>
            <Col lg={6} className="d-flex justify-content-end">
              <Button className="btn-sm mx-2" onClick={handleSubmit}>
                Save
              </Button>
            </Col>
          </Row>

          <Row className="pb-5 py-3">
            <Col lg={6}>
              <Form.Group className="mx-3 " controlId="formBasicPhone">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={profile.firstname}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicLastName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  placeholder="Enter Last Name"
                  value={profile.lastname}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicLastEmail">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  value={profile.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group
                className="mb-3"
                controlId="formBasicPhoneName"
                name="phone"
              >
                <Row className="align-items-center">
                  <Form.Label
                    className="form-view-label mx-3"
                    htmlFor="formBasicPhone"
                  >
                    Phone
                  </Form.Label>
                  <Col lg={4}>
                    <Form.Select
                      size="md"
                      className="mx-3"
                      onChange={handleChange}
                      name="countryCode"
                      placeholder="select"
                      //required
                      value={profile.countryCode}
                    >
                      {showList &&
                        options1?.map((obj, idx) => (
                          <option key={idx} value={obj.dialCode}>
                            {obj.dialCode} ({obj.code})
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg={8}>
                    <Form.Control
                      type="phone"
                      name="phone"
                      placeholder="Phone Number "
                      //value={`${profile.countryCode || options1[0]?.dialCode}-${profile.phone}`}
                      value={profile.phone}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col lg={1}></Col>
      </Row>

      <Modal show={show} onHide={handleClose} className="modal-sm">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col></Col>
            <Col lg={12}>
              <Form className="mt-3">
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        New Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter Your password"
                        onChange={handlePasswordOnchange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmpassword"
                        placeholder="Enter confirm password"
                        onChange={handlePasswordOnchange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangeSubmit}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditProfile;
