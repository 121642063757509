import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { ShimmerTable } from "react-shimmer-effects";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const ProductList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();
  useEffect(() => {
    async function init() {
      const result = await SparkApi.fetchProducts();
      console.log("result", result);

      if (result) {
        setBody(result);
        setProducts(result);
      } else {
        setBody([]);
        setProducts([]);
      }
    }
    fetchAllCategories();
    init();
  }, []);

  const fetchAllCategories = async () => {
    const result = await SparkApi.fetchCategories();
    if (result) {
      setCategories(result);
    } else {
      setCategories([]);
    }
  };

  const onFilterType = (event) => {
    const typeFilterValue = event.target.value;

    if (typeFilterValue === "") {
      setBody(products);
    } else {
      setBody(
        products.filter((data) => {
          if (
            (data.recordtypeid || "").toLowerCase() ===
            typeFilterValue.toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  const onFilterCategory = (event) => {
    const categoryFilterValue = event.target.value; //CategoryId
    if (categoryFilterValue === "") {
      setBody(products);
    } else {
      setBody(
        products.filter((data) => {
          //Category id match
          if (
            (data.category || "").toLowerCase() ===
            categoryFilterValue.toLowerCase()
          ) {
            return data;
          }
          return null;
        })
      );
    }
  };

  // Create table headers consisting of 4 columns.

  const header = [
    {
      title: "Product Name",
      prop: "productname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/products/" + row.id} state={row}>
          {row.productname}
        </Link>
      ),
    },
    {
      title: "Category Name",
      prop: "categoryname",
      isFilterable: true,
      //cell: (row) => <Link to={"/products/" + row.id}>{row.lastname}</Link>,
    },
    { title: "Product Code", prop: "productcode" },
    { title: "Product Sort Order", prop: "sortorder" },
    {
      title: "Offer",
      prop: "offerlabel",
      cell: (row) => (
        <span
          className={row.showoffer ? "badge bg-success" : "badge bg-danger"}
        >
          {row.offerlabel}
        </span>
      ),
    }, //yamini 25-08-2023
    {
      title: "Status",
      prop: "isactive",
      cell: (row) => (
        <span className={row.isactive ? "badge bg-success" : "badge bg-danger"}>
          {row.isactive ? "Active" : "Inactive"}{" "}
        </span>
      ),
    },
    // { title: "City", prop: "city", isFilterable: true },
    // { title: "Email", prop: "email", isFilterable: true },
    // { title: "Phone", prop: "phone", isFilterable: true },
    // { title: "Contact Type", prop: "recordtypeid", isFilterable: true },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createProduct = () => {
    navigate(`/products/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={12} className="px-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={6}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicCategory">
                  <Form.Select
                    aria-label="Enter category"
                    name="category"
                    onChange={onFilterCategory}
                  >
                    <option value="">Select Category</option>
                    {categories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <InfoPill left="Total" right={body?.length} />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createProduct(true)}
                >
                  New Product
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          <ShimmerTable row={10} col={8} />
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default ProductList;
