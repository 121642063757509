import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import SparkApi from "../api/SparkApi";
import jwt_decode from "jwt-decode";
import PubSub from "pubsub-js";
import { WhatsappIcon } from "react-share";
import ReactStars from "react-stars";

const Setting = forwardRef((props, ref) => {
  const [body, setBody] = useState([]);
  const [configuration, setConfiguration] = useState([]);
  const [selectInputs, setSelectInputs] = useState([
    { id: "input-0", value: " " },
  ]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const tableName = "product";
  const [values, setValues] = useState({
    productname: "Unwind by Yellow Chimes",
    productcode: "18971406",
    description:
      "White Crystal Studded Drop Butterfly Designed Gold Plated Earring",
    gst: 5,
    isdeleted: "Is Deleted",
    unitcount: "Unit Count",
    recordtypeid: "",
    lastmodifieddate: "20-02-2020",
    createddate: "10-02-2020",
    isactive: true,
    productweight: "product weight",
    height: "6CM",
    width: "2CM",
    type: "Drop Earrings",
    makingcharge: "990",
    videourl: "video url",
    sortorder: "sort order",
    price: "366",
    formatted_description: (
      <>
        <table
          className="formatted-desc"
          style={{ width: "100%", textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>
                <div>
                  Size
                  <br />
                </div>
              </th>
              <th>
                <div>
                  Price
                  <br />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>M</div>
              </td>
              <td>
                <div>
                  450 <strong>Rs/-</strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
      </>
    ),
    rating: <ReactStars count={5} size={18} color2={"#dc3545"} value={4} />,
    offerlabelcolor: "#309526",
  });

  useEffect(() => {
    async function init() {
      let column = [];
      column = await SparkApi.fetchFieldsByTable(tableName);
      column.push({ column_name: "rating", data_type: "character varying" });
      column.push({ column_name: "offerlabelcolor", data_type: "color" });
      console.log("column  :-  ", column);
      if (column) {
        setBody(column);
      } else {
        setBody([]);
      }

      const configurationSetting =
        await SparkApi.fetchCompanySettingByCompanyId();
      console.log("configurationSetting", configurationSetting);
      const data = JSON.parse(configurationSetting.configuration);
      console.log("data", data);
      console.log("data.offerlabelcolor", data.offerlabelcolor);
      // Start yamini 31-08-2023
      setColor(data.offerlabelcolor);
      setRibbonColor({
        backgroundColor:
          data.offerlabelcolor === undefined ? "#309526" : data.offerlabelcolor,
      });
      setValues((prevValues) => ({
        ...prevValues,
        offerlabelcolor: data.offerlabelcolor,
      }));
      // End yamini 31-08-2023
      if (data) {
        setConfiguration(data);
        let count = 0;
        let inputs = [];
        data.fields.forEach((element) => {
          if (element.sortOrder === 1 && element.isPrimary) {
            setSelectedName(element.value);
          } else if (element.sortOrder === 2 && element.isPrimary) {
            setSelectedRating(element.value);
          } else if (element.sortOrder === 3 && element.isPrimary) {
            setSelectedDescription(element.value);
          } else {
            if (inputs.length === 0) {
              inputs = [{ id: `input-${count}`, value: element.value }];
            } else {
              inputs = [
                ...inputs,
                { id: `input-${count}`, value: element.value },
              ];
            }
            count++;
          }
        });
        setSelectInputs(inputs);
      } else {
        setConfiguration([]);
      }
    }
    init();
  }, []);

  const addSelectInput = () => {
    const hasEmptyInput = selectInputs.some(
      (input) => input.value === "Choose..." || input.value.trim() === ""
    );
    if (hasEmptyInput) {
      setSelectInputs((prevInputs) =>
        prevInputs.map((input) =>
          input.value === "Choose..." || input.value.trim() === ""
            ? { ...input, isInvalid: true }
            : input
        )
      );
    } else if (selectInputs.length < 18) {
      const newInputId = `input-${selectInputs.length}`;
      setSelectInputs([...selectInputs, { id: newInputId, value: "" }]);
    }
  };

  const removeSelectInput = (inputId) => {
    setSelectInputs(selectInputs.filter((input) => input.id !== inputId));
  };

  const handleSelectChange = (e, inputId) => {
    const selectedValue = e.target.value;
    setSelectInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id === inputId
          ? { ...input, value: selectedValue, isInvalid: false }
          : input
      )
    );
  };

  const handleSave = async () => {
    let jsonData = [
      {
        sortOrder: 1,
        label: selectedName,
        value: selectedName,
        isPrimary: true,
      },
      {
        sortOrder: 2,
        label: selectedRating,
        value: selectedRating,
        isPrimary: true,
      },
      {
        sortOrder: 3,
        label: selectedDescription,
        value: selectedDescription,
        isPrimary: true,
      },
    ];
    jsonData = jsonData.filter((item) => item.label !== "Choose...");
    const maxSortOrder = jsonData.reduce(
      (max, item) => Math.max(max, item.sortOrder),
      0
    );
    let count = 0;
    selectInputs.forEach((input, index) => {
      const value = input.value.trim();
      if (value && value !== "Choose...") {
        const fieldData = {
          sortOrder: maxSortOrder + count + 1,
          label: value,
          value: value,
          isPrimary: false,
        };
        jsonData = [...jsonData, fieldData];
        count++;
      }
    });
    let myarray = [];
    jsonData.forEach((data, index) => {
      data.sortOrder = index + 1;
      myarray.push(data);
    });
    const jsonString = JSON.stringify(myarray);
    console.log(jsonString);
    let userInfo = jwt_decode(localStorage.getItem("token"));
    let companySettingForInsert = {
      name: `${userInfo.tenantcode}_card_setting`,
      companyId: userInfo.tenantcode,
      configuration: {
        mode: props.mode,
        offerlabelcolor: values.offerlabelcolor,
        fields: myarray,
      },
    };
    console.log("companySettingForInsert  :-  ", companySettingForInsert);
    const companySetting = await SparkApi.updateCompanySetting(
      companySettingForInsert
    );
    console.log("companySetting  -  ", companySetting);

    PubSub.publish("RECORD_SAVED_TOAST", {
      title: "Record Saved",
      message: "Record saved successfully",
    });
  };

  const myStyle = {
    backgroundImage:
      "url('https://digimenu.indicrm.io/images/ibs_kajew/4b4daceb-97b5-4e50-a98f-a72992105dd9/a920fcb9-b6b2-4a5f-8f07-d8cceaf7ce4d.jpg')",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    borderBottom: "1px solid #dee2e6",
    maxHeight: "250px",
    minHeight: "400px",
  };

  const getStyle = (index) => {
    return index % 2 === 0 ? { float: "left" } : { float: "right" };
  };

  useImperativeHandle(ref, () => ({
    saveSettings() {
      handleSave();
    },
  }));

  // Start yamini 31-08-2023
  const [color, setColor] = useState();

  const [ribbonColor, setRibbonColor] = useState({
    backgroundColor: color,
  });

  const colorChange = (e) => {
    const newColor = e.target.value;
    setColor(newColor);
    setValues((prevValues) => ({
      ...prevValues,
      offerlabelcolor: newColor,
    }));
    setRibbonColor({
      backgroundColor: newColor,
    });
  };

  // End yamini 31-08-2023

  return (
    <>
      {/* <div className="" style={{    backgroundColor: 'white', textAlign: 'end'}}>
          <div>
            <button className="btn btn-success p-1 me-2 btn-circle" onClick={handleSave}>
              Save
            </button>
          </div>
        </div> */}
      <Container>
        <Row className="mt-3">
          <Col lg={4} className="d-flex">
            <div className="card product-card flex-fill">
              {/*yamini 31-08-2023 */}
              <div class="ribbon-2" style={ribbonColor}>
                25% Off 
              </div>
              <div className="card-img-container" style={myStyle}></div>
              <div className="card-body">
                <div className="d-flex align-items justify-content-between">
                  <Row>
                    <Col lg={6}>
                      <div className="input-group input-group-sm">
                        <select
                          className="form-select"
                          data-role="select-dropdown"
                          value={selectedName}
                          onChange={(e) => setSelectedName(e.target.value)}
                        >
                          <option selected>Choose...</option>
                          {body.map((column) => (
                            <option value={column.column_name}>
                              {column.column_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div style={{ lineHeight: "20px" }}>
                        <div className="input-group input-group-sm w-50 float-end">
                          <select
                            className="form-select"
                            data-role="select-dropdown"
                            value={selectedRating}
                            onChange={(e) => setSelectedRating(e.target.value)}
                          >
                            <option selected>Choose...</option>
                            {body.map((column) => (
                              <option value={column.column_name}>
                                {column.column_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="pt-2">
                      <div className="input-group input-group-sm ">
                        <select
                          className="form-select"
                          data-role="select-dropdown"
                          value={selectedDescription}
                          onChange={(e) =>
                            setSelectedDescription(e.target.value)
                          }
                        >
                          <option selected>Choose...</option>
                          {body.map((column) => (
                            <option value={column.column_name}>
                              {column.column_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="col-sm-12 pt-2">
                  <Row id="inputContainer">
                    {selectInputs.map((input) => (
                      <Col lg={6} className="p-1">
                        <div className="input-group input-group-sm">
                          <span className="input-group-addon p-1">
                            <i
                              className="fa fa-minus float-end fa-border text-white bg-danger"
                              type="button"
                              onClick={() => removeSelectInput(input.id)}
                            ></i>
                          </span>
                          <select
                            key={input.id}
                            className={`form-select ${
                              input.isInvalid ? "empty-input" : ""
                            }`}
                            data-role="select-dropdown"
                            value={input.value}
                            onChange={(e) => handleSelectChange(e, input.id)}
                          >
                            <option selected>Choose...</option>
                            {body.map((column) => (
                              <option value={column.column_name}>
                                {column.column_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="d-flex align-items justify-content-between pt-1">
                  <div className="text-center">
                    <WhatsappIcon size={24} round />
                    <br />
                    Enquiry
                  </div>
                  <div className="text-center">
                    <i
                      className="fa-solid fa-arrow-up-from-bracket"
                      style={{
                        fontSize: "1.5rem",
                        color: "rgba(71, 82, 93, 0.8)",
                        cursor: "pointer",
                      }}
                    ></i>
                    <br />
                    Share
                  </div>
                </div>
                <div className="d-flex align-items justify-content-between pt-1">
                  {/* yamini 31-08-2023 */}
                  <div className="text-start">
                    <input
                      type="color"
                      name="offerlabelcolor"
                      value={color}
                      onChange={colorChange}
                    />
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={addSelectInput}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={1}></Col>

          <Col lg={4} className="d-flex">
            <div className="card product-card flex-fill">
              {/* yamini 31-08-2023 */}
              <div class="ribbon-2" style={ribbonColor}>
                25% Off
              </div>
              <div className="card-img-container" style={myStyle}></div>
              <div className="card-body">
                <div className="d-flex align-items justify-content-between">
                  <Row>
                    <Col lg={6}>
                      <h5
                        className="card-title float-left"
                        style={{
                          color: "rgb(79, 50, 103)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "14rem",
                        }}
                      >
                        {selectedName !== "Choose..." ? (
                          <>{values[selectedName]}</>
                        ) : (
                          ""
                        )}
                      </h5>
                    </Col>
                    <Col lg={6}>
                      <div style={{ lineHeight: "20px" }} className="float-end">
                        {selectedRating !== "Choose..." ? (
                          <>{values[selectedRating]}</>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg={12} className="pt-3">
                      {selectedDescription !== "Choose..." ? (
                        <>{values[selectedDescription]}</>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="col-sm-12 pt-1">
                  <Row id="selectedValues">
                    {selectInputs.map((input, index) => (
                      <Col lg={6} key={index}>
                        <div
                          className="form-group p-1 text-uppercase"
                          style={getStyle(index)}
                        >
                          <b>{input.value}</b>
                          <br /> {values[input.value]}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="d-flex align-items justify-content-between pt-1">
                  <div className="text-center">
                    <WhatsappIcon size={24} round />
                    <br />
                    Enquiry
                  </div>
                  <div className="text-center">
                    <i
                      className="fa-solid fa-arrow-up-from-bracket"
                      style={{
                        fontSize: "1.5rem",
                        color: "rgba(71, 82, 93, 0.8)",
                        cursor: "pointer",
                      }}
                    ></i>
                    <br />
                    Share
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default Setting;
