import logo from "./logo.svg";

import "./App.css";
import "./Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { AccordionCollapse, Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";

import LeadList from "./components/LeadList";
import LeadView from "./components/LeadView";
import LeadEdit from "./components/LeadEdit";

import AccountList from "./components/AccountList";
import AccountView from "./components/AccountView";
import AccountEdit from "./components/AccountEdit";
import ContactList from "./components/ContactList";
import ContactView from "./components/ContactView";
import ContactEdit from "./components/ContactEdit";
import ContactCreate from "./components/ContactCreate";

import MiniSidebar from "./components/MiniSidebar";
import * as constants from "./constants/CONSTANT";
import ChangePassword from "./components/ChangePassword";
import ReportList from "./components/ReportList";
import Calendar from "./components/Calendar";

import PubSub from "pubsub-js";
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import UserView from "./components/UserView";
import EditProfile from "./components/EditProfile";
import UserTracking from "./components/UserTracking";
import BusinessList from "./components/BusinessList";
import BusinessView from "./components/BusinessView";
import BusinessEdit from "./components/BusinessEdit";
import Notification from "./components/Notification";
import BackupList from "./components/BackupList";
import jwt_decode from "jwt-decode";
import CategoryList from "./components/CategoryList";
import ItemList from "./components/ItemList";
import OrderList from "./components/OrderList";
import OrderEdit from "./components/OrderEdit";
import OrderView from "./components/OrderView";
import NavBar from "./components/NavBar";
import ProductGridView from "./components/ProductGridView";
import CompanySettingContainer from "./components/CompanySettingContainer";
import ProductDetail from "./components/ProductDetail";
import Support from "./components/Support";
import ProductList from "./components/ProductList";
import ProductEdit from "./components/ProductEdit";
import ProductView from "./components/ProductView";
import SparkApi from "./api/SparkApi";
import Setting from "./components/Setting";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationHI from "./locales/hi/translation.json";
import QrCode from "./components/QrCode";
import Category from "./components/Category";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  hi: {
    translation: translationHI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("Confirmation");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  // const [isInternalUser, setIsInternalUser] = useState(true);

  const [tenantCode, setTenantCode] = useState([]);
  const [companyInfoData, setCompanyInfo] = useState([]);
  const [isCompanyAvailable, setIsCompanyAvailable] = useState(false);

  const mySubscriber = (msg, data) => {
    switch (msg) {
      case "RECORD_SAVED_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("success");
        //console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case "RECORD_ERROR_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("danger");
        //console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //console.log('mytopic: default'); // add your desired log message
        break;
    }
  };
  useEffect(() => {
    //dispatch(fetchAccounts());
    PubSub.subscribe("RECORD_SAVED_TOAST", mySubscriber);
    PubSub.subscribe("RECORD_ERROR_TOAST", mySubscriber);
    try {
      if (localStorage.getItem("token")) {
        let user = jwt_decode(localStorage.getItem("token"));
        setUserInfo(user);
        // console.log('user  :-  ',user);
        // if(user.userrole === 'USER') {
        //   setIsInternalUser(false);
        // }

        var perm = user.permissions
          .map(function (obj) {
            return obj.name;
          })
          .join(";");
        setPermissions(perm);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("/menu/ibs_")) {
      let tCode = window.location.pathname.split("/")[2];
      console.log(tCode);
      setTenantCode(tCode);
      async function init() {
        if (tCode) {
          let companyInfo = await SparkApi.fetchComanyInfoByTenantCode(tCode);
          console.log("companyInfo  ", companyInfo);
          if (companyInfo) {
            setCompanyInfo(companyInfo);
            setIsCompanyAvailable(true);
            console.log("locale ", companyInfo.locale);
            i18n.changeLanguage(companyInfo.locale);
            if (companyInfo.locale == "ar") {
              document.documentElement.style.setProperty(
                "--menu-font-family",
                `'Noto Naskh Arabic', serif`
              );
              document.documentElement.style.setProperty(
                "--menu-direction",
                `rtl`
              );
            }
          }
        }
      }
      init();
    }
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position="top-center">
        <Toast
          show={modalShow}
          onClose={() => setModalShow(false)}
          delay={3000}
          bg={variant}
          className="text-white"
          autohide
        >
          {variant === "success" ? (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#198754", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          ) : (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#DC3545", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          )}

          <Toast.Body>
            {message instanceof Array ? (
              message.map((item) => {
                return <span>{item.msg}</span>;
              })
            ) : message instanceof Object ? (
              <span>{message.detail}</span>
            ) : (
              <span>{message}</span>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <div className="wrapper">
                <Sidebar />
                <div id="content">
                  <Header />
                  <Home />
                </div>
              </div>
            }
          />
          <Route
            path="/menu/:tanentcode"
            element={
              <div>
                {isCompanyAvailable ? (
                  <>
                    {companyInfoData.isactive ? (
                      <>
                        <NavBar companyName={companyInfoData.name} />
                        <div id="content">
                          <ProductGridView companyInfoData={companyInfoData} />
                        </div>
                        <Footer companyInfoData={companyInfoData} />
                      </>
                    ) : (
                      <div
                        className="p-20 text-center"
                        style={{ background: "white" }}
                      >
                        <h2>Please contact our support.</h2>
                        <a href="https://indicrm.io">https://indicrm.io</a>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            }
          />
          <Route
            path="/menu/:tanentcode/:language"
            element={
              <div>
                <NavBar companyName={companyInfoData.name} />
                <div id="content">
                  <ProductGridView companyInfoData={companyInfoData} />
                </div>
                <Footer companyInfoData={companyInfoData} />
              </div>
            }
          ></Route>
          {/******** Show All Leadss *******/}
          <Route
            path="/leads"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadList />
                    {/* {permissions && (permissions.indexOf(constants.VIEW_LEAD) >= 0)?
                      <LeadList /> : 'You have no permission to access account'} */}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/users"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    permissions.indexOf(constants.MODIFY_ALL) >= 0 ? (
                      <UserList />
                    ) : (
                      <Alert
                        error-alert
                        variant="danger"
                        className="alert error-alert"
                      >
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You
                        haven’t have any access
                      </Alert>
                    )}
                  </div>
                </div>
              </>
            }
          />

          {/******** Edit Profile *******/}
          <Route
            path="/myprofile"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EditProfile />
                  </div>
                </div>
              </>
            }
          />

          {/*  Create Lead  */}

          <Route
            path="users/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                </div>
              </>
            }
          />
          {/******** Update Users  *******/}
          <Route
            path="users/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                </div>
              </>
            }
          />

          {/******** Show an User By Id *******/}
          <Route
            path="users/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserView />
                  </div>
                </div>
              </>
            }
          />

          {/******** Show an Lead By Id *******/}
          <Route
            path="users/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadView />
                  </div>
                </div>
              </>
            }
          />

          {/******** Create Lead  *******/}
          <Route
            path="users/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />
          {/******** Update Lead  *******/}
          <Route
            path="users/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/changepassword"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ChangePassword />
                  </div>
                </div>
              </>
            }
          />
          {/* <Route path="reports/:id" element={
            <>
              <div className="wrapper">
                <Sidebar />
                <div id="content">
                  <Header />
                  <ReportView />
                </div>
              </div>
            </>
          }
          /> */}
          <Route
            path="/reports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportList />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/backup"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BackupList />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/categories"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CategoryList />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/items"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ItemList />
                  </div>
                </div>
              </>
            }
          />

          {/******** Create a product *******/}
          <Route
            path="/products/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    permissions.indexOf(constants.MODIFY_ALL) >= 0 ? (
                      <ProductEdit />
                    ) : (
                      "You have no permission to access account"
                    )}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show All products *******/}

          <Route
            path="/products"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {permissions &&
                    (permissions.indexOf(constants.VIEW_PRODUCT) >= 0 ||
                      permissions.indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <ProductList />
                    ) : (
                      "You have No permission for product"
                    )}
                  </div>
                </div>
              </>
            }
          />
          {/******** Show a Product By Id *******/}
          <Route
            path="products/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProductView />
                  </div>
                </div>
              </>
            }
          />
          {/******** Edit product By Id *******/}
          <Route
            path="products/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProductEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/setting"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CompanySettingContainer />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/menu/:tanentcode/p/:id"
            element={
              <div>
                <NavBar companyName={companyInfoData.name} />
                <div id="content">
                  <ProductDetail companyInfoData={companyInfoData} />
                </div>
                <Footer companyInfoData={companyInfoData} />
              </div>
            }
          />

          <Route
            path="/support"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Support />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/qrcode"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <QrCode />
                  </div>
                </div>
              </>
            }
          />
          {/* yamini 22-8-2023 */}
           <Route
            path="/menu/:tanentcode/c/:id"
            element={
              <div>
                <NavBar companyName={companyInfoData.name} />
                <div id="content">
                  <Category companyInfoData={companyInfoData} />
                </div>
                <Footer companyInfoData={companyInfoData} />
              </div>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
