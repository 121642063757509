import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";





const Footer = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  useEffect(() => {
    console.log('footer prop ' , props);
  }, [props]);

  const openGoogleMap = (address) => {
    window.open(`https://maps.google.com/?q=${encodeURI(address)}`, "_blank");


  }

  // const location = useLocation();
  // const [tenantCode , setTenantCode] = useState([]);
  // const [companyInfoData , setCompanyInfo] = useState([]);
  // useEffect(() => {
  //   let tCode = location.pathname.split('/')[2];
  //   setTenantCode(tCode);

  //   async function init() {
  //     let comapnyInfo = await  SparkApi.fetchComanyInfoByTenantCode(tCode);
  //     console.log('comapnyInfo  ' , comapnyInfo);
  //     if(comapnyInfo){
  //       setCompanyInfo(comapnyInfo);
  //     }
  //   }
  //   init();
  // }, [])

  return (

    <footer class="footer-04 pt-4">
      <div class="container">
      {/* <center><i><b>Disclaimer:</b> Images & Vedios on this demo website are mostly taken from <a href="https://www.bluestone.com/">Bluestone</a> Website and others. We DO NOT own these content. Contents are used here only for demo purpose.</i></center> */}
       <Row style={{margin: '0px'}}>
        <Col lg={4} md={6} className="pt-3 pb-3 text-center" style={{paddingLeft: '0.5rem'}}>
        <div>
        {t("poweredby")}  
            </div>
            <a href="https://indicrm.io" target="_blank">
            <img src="/indicrm.png" style={{width: "140px", paddingTop: ".5rem"}}/>
            </a>
            <div style={{color: "#fff", fontSize: "1.4rem", paddingLeft: ".5rem"}}>
            DigiMenu
            </div>
        </Col>
        <Col lg={5} md={6} className="pt-3 pb-3 text-center" style={{paddingLeft: '0.5rem'}}>
        <div>
        {t("company")} 
            </div>
            <a href="https://indicrm.io" target="_blank">
            
            </a>
            <div style={{color: "#fff", fontSize: "1rem"}}>
            {props?.companyInfoData?.name} <br/>
            <span>
            
             &nbsp; {props?.companyInfoData?.address} <br/>
            {/* 'Tel: +91-9414523967' */}
            <i style={{cursor:"pointer", color : "#ef4236", fontSize : "1.4rem"}} className="py-1 fas fa-map-marker-alt" onClick={() => openGoogleMap(props?.companyInfoData?.address)}></i>
            <br/>
            {props?.companyInfoData.phone ?  `${t("tel")}  : ${props.companyInfoData.phone}` : ''}
            </span>
            </div>
        </Col>
        <Col lg={3} md={12} className="text-center pt-3 pb-3 social-icon" style={{borderTop: "1px solid #f3f3f3"}}>
        
              <a href="#">
                <span class="fa-stack fa-2x">
                  <i
                    class="fa-solid fa-circle fa-stack-1x"
                    style={{ color: "#3b5998", fontSize: "4rem" }}
                  ></i>
                  <i
                    class="fa-brands fa-facebook-f fa-stack-1x"
                    style={{ color: "#fff", fontSize: "2rem" }}
                  ></i>
                </span>
              </a>
              <a href="#">
                <span class="fa-stack fa-2x">
                  <i
                    class="fa-solid fa-circle fa-stack-1x"
                    style={{ color: "tomato", fontSize: "4rem" }}
                  ></i>
                  <i
                    class="fa-brands fa-google fa-stack-1x"
                    style={{ color: "#fff", fontSize: "2rem" }}
                  ></i>
                </span>
              </a>
              <a href="#">
                <span class="fa-stack fa-2x">
                  <i
                    class="fa-solid fa-circle fa-stack-1x"
                    style={{ color: "#0d95e8", fontSize: "4rem" }}
                  ></i>
                  <i
                    class="fa-brands fa-twitter fa-stack-1x"
                    style={{ color: "#fff", fontSize: "2rem" }}
                  ></i>
                </span>
              </a>
              {/* <a href="#">
                <span class="fa-stack fa-2x">
                  <i
                    class="fa-solid fa-circle fa-stack-1x"
                    style={{ color: "tomato", fontSize: "4rem" }}
                  ></i>
                  <i
                    class="fa-brands fa-google fa-stack-1x"
                    style={{ color: "#fff", fontSize: "2rem" }}
                  ></i>
                </span>
              </a>
              <a href="#">
                <span class="fa-stack fa-2x">
                  <i
                    class="fa-solid fa-circle fa-stack-1x"
                    style={{ color: "#0d95e8", fontSize: "4rem" }}
                  ></i>
                  <i
                    class="fa-brands fa-twitter fa-stack-1x"
                    style={{ color: "#fff", fontSize: "2rem" }}
                  ></i>
                </span>
              </a> */}
        </Col>
       </Row>
         
        
         
      </div>
      {/* <div class="w-100 mt-3 text-center" style={{borderTop: "1px solid rgb(147 147 147)", paddingTop : "1rem"}}>
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <p class="copyright">
               
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
// 